import React, { Component } from 'react';
import { Button, Input, Select } from 'antd';
import Colors from '../constants/Colors'
import Helpers from '../utils/Helpers'


export default class DiscountInput extends Component {
    constructor(props) {
        super(props)
        this.state = {
            discountValue: 0,
            discountType: 'value'
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps && nextProps.value)
        this.setState({discountType: nextProps.value.type, discountValue: nextProps.value.value});
    }

    value = () => {
        if(this.state.discountValue == 0) return;
        return {type: this.state.discountType, value: this.state.discountValue};
    }

    setDiscountType = (type) => {
        this.setState({discountType: type}, ()=>{
            if(this.props.onChange) this.props.onChange(this.value());
        });
    }

    setDiscountVal = (val) => {
        if(!val) val = "0";
        var rawVal = parseInt(val.match(/\d+/g).join(''));
        var v = rawVal/100;
        this.setState({discountValueStr: v.toFixed(2).replace('.',','), discountValue: v}, ()=>{
            if(this.props.onChange) this.props.onChange(this.value());
        });
    }

    render() {
        return <Input placeholder="Desconto" className="ant-input-lg txCenter" value={this.state.discountValueStr}
            onChange={(e) => this.setDiscountVal(e.target.value)}
            addonBefore={<Select style={{ width: 70, fontSize: 16, textAlign: 'center' }} 
                value={this.state.discountType} onChange={(val)=>{ this.setDiscountType(val) }}>
                <Select.Option value="value">R$</Select.Option>
                <Select.Option value="percent">%</Select.Option>
            </Select>}
        />
    }
}