import React, { Component } from 'react';
import { Button, Icon, Modal, Input, Layout, Form, Menu, Radio, Card, Tag } from 'antd';
import Colors from '../constants/Colors'
import Helpers from '../utils/Helpers'
import { PaymentMethod, PaymentMethodBR, PaymentType } from '../constants/Enums'
var Big = require('big.js');

export default class ProductRow extends Component {
    render() {
        return (<div className={this.props.onClick ? 'clickable' : ''} style={{
            padding: 15, borderBottom: this.props.noBorder ? 'none' : '1px solid #eee', minHeight: 60,
            flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', display: 'flex'

        }} onClick={() => { if (this.props.onClick) this.props.onClick(this.props.product) }}>
            <div style={{ minWidth: 40 }}>
                <Button shape="circle" style={styles.prodQtd}>{this.props.product.Bulk ? 1 : this.props.product.Quantity}</Button>
            </div>
            <div style={styles.prodName}>
                {this.props.product.Name} {this.props.product.Bulk ? <b>({this.props.product.Quantity} {this.props.product.QtyName})</b> : null} {this.props.product.discount ? (this.props.product.discount.type == 'percent' ? <Tag color="green">{this.props.product.discount.value}% OFF</Tag> : <Tag color="green">R$ {this.props.product.discount.value} OFF</Tag>) : ''}
            </div>
            <div style={{ minWidth: 80, textAlign: 'right' }}>
                {this.props.product.Price &&
                    <span style={styles.prodPrice}>{this.props.product.Bulk ?
                        Helpers.FloatToReais(Number(new Big(this.props.product.Price).times(this.props.product.Quantity))) :
                        Helpers.FloatToReais(Number(this.props.product.Price))
                    }</span>
                }
                {this.props.product.SiteSale && 
                    <Tag color="blue" style={{position:'relative', padding: "0px 5px", height: 18, lineHeight:"14px", float:'right',marginRight:0}}>
                        preço site
                    </Tag>}
            </div>
        </div>)
    }
}

const styles = {
    prodName: {
        fontSize: 16,
        width: '100%'
    },
    prodPrice: {
        fontWeight: 'bold',
        fontSize: 16
    },
    prodQtd: {
        fontSize: 18
    }
}