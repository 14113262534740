import React, { Component } from 'react';
import { Button, Icon, Input, Layout, Form } from 'antd';
import CardReactFormContainer from 'card-react';
import Helpers from '../utils/Helpers'
export default class CreditCardInput extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cardName: '',
            cardNumber: '',
            cardExpiry: '',
            cardCvc: ''
        }
    }

    render() {
        return <div style={{ padding: 15 }}>
            <CardReactFormContainer

                // the id of the container element where you want to render the card element.
                // the card component can be rendered anywhere (doesn't have to be in ReactCardFormContainer).
                container="card-wrapper" // required

                // an object contain the form inputs names.
                // every input must have a unique name prop.
                formInputsNames={
                    {
                        number: 'CCnumber', // optional — default "number"
                        expiry: 'CCexpiry',// optional — default "expiry"
                        cvc: 'CCcvc', // optional — default "cvc"
                        name: 'CCname' // optional - default "name"
                    }
                }

                // initial values to render in the card element
                //initialValues={
                //    {
                //        number: '4242424242424242', // optional — default •••• •••• •••• ••••
                //        cvc: '123', // optional — default •••
                //        expiry: '16/12', // optional — default ••/••
                //        name: 'Random Name' // optional — default FULL NAME
                //    }
                //}

                // the class name attribute to add to the input field and the corresponding part of the card element,
                // when the input is valid/invalid.
                classes={
                    {
                        valid: 'valid-input', // optional — default 'jp-card-valid'
                        invalid: 'invalid-input' // optional — default 'jp-card-invalid'
                    }
                }

                // specify whether you want to format the form inputs or not
                formatting={true} // optional - default true
            >

                <Form onSubmit={this.onEditProductOk} style={{ width: 350, margin: 'auto' }}>
                    <Form.Item
                        validateStatus={!this.state.prodNameOk ? 'error' : ''}
                        help={this.state.prodNameError || ''}
                    >
                        <Input placeholder="•••• •••• •••• ••••" type="text" name="CCnumber" onChange={(e) => this.setState({ cardNumber: e.target.value })} />
                    </Form.Item>
                    <Form.Item
                        validateStatus={!this.state.prodNameOk ? 'error' : ''}
                        help={this.state.prodNameError || ''}
                    >
                        <Input placeholder="Nome do cliente no cartão" type="text" name="CCname" onChange={(e) => this.setState({ cardName: e.target.value })} />
                    </Form.Item>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ flex: 1, marginRight: 20 }}>
                            <Form.Item
                                validateStatus={!this.state.prodNameOk ? 'error' : ''}
                                help={this.state.prodNameError || ''}
                            >
                                <Input placeholder="MM/AA" type="text" name="CCexpiry" onChange={(e) => this.setState({ cardExpiry: e.target.value })} />
                            </Form.Item>
                        </div>
                        <Form.Item
                            validateStatus={!this.state.prodNameOk ? 'error' : ''}
                            help={this.state.prodNameError || ''}
                        >
                            <Input placeholder="Cód. Segurança" type="text" name="CCcvc" onChange={(e) => this.setState({ cardCvc: e.target.value })} />
                        </Form.Item>
                    </div>
                </Form>

            </CardReactFormContainer>
            <div id="card-wrapper"></div>
        </div>
    }
}