import React, { Component } from 'react';
import { Form, Icon, Input, Button, Checkbox, Layout } from 'antd';
import Api from '../api/Api';
import sha256 from 'crypto-js/sha256';
import store from '../store/store';
import { setUserAuth } from '../store/authReducer';
import { addUser } from './../store/usersReducer';
import { connect } from 'react-redux';
const FormItem = Form.Item;

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = { user: '', pass: '', loading: false };
  }

  tryLoginOffline = () => {
    var mySHa256Pass = sha256(this.state.pass).toString();
    var offUser = this.props.users.filter(x => x.user == this.state.user);
    if (offUser.length > 0 && offUser[0].pass == mySHa256Pass) { // can login offline
      console.log('loggin offline user:', offUser[0])
      this.props.dispatch(setUserAuth(offUser[0]));
      this.setState({ loading: false, feedbackMsg: '' });
      return;
    } else {
      this.setState({ loading: false })
      return false;
    }
  }

  login = () => {
    this.setState({ loading: true, feedbackMsg: '' });
    var mySHa256Pass = sha256(this.state.pass).toString();

    console.log('trying login online...');
    Api.Login(this.state.user, this.state.pass)
      .then((e) => {
        console.log('back', e);
        if (e && e.data && e.data.token) {
          console.log('logged in ok:', e.data);
          this.props.dispatch(addUser({ ...e.data, pass: mySHa256Pass }))
          this.props.dispatch(setUserAuth({ ...e.data }));
          // salvar dados do user e logar ele, dps fazer ele logar offline antes de tentar online se o token ainda tiver valido
          // usar o /validate da api pra ver se ta ok ainda, sera?
          this.setState({ loading: false })
        } else {
          this.setState({ loading: false, feedbackMsg: 'Ops, dados incorretos' });
        }
      }).catch(e => {
        // try login offline first:
        console.log('trying login offline...');
        this.tryLoginOffline();
        this.setState({ feedbackMsg: 'Ocorreu um erro! Tentando login offline...' });
      })
  }

  render() {
    return (
      <Layout style={{ justifyContent: 'center', alignItems: 'center' }}>
        <Form onSubmit={this.handleSubmit} className="login-form" style={{ maxWidth: 320, alignItems: 'center' }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: 30 }}>
            <Icon type="lock" style={{ fontSize: 120, color: '#aaa' }} theme="outlined" />
          </div>
          <FormItem>
            <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="login"
              value={this.state.user}
              onChange={(e) => this.setState({ user: e.currentTarget.value })}
            />
          </FormItem>
          <FormItem>
            <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="senha"
              value={this.state.pass}
              onChange={(e) => this.setState({ pass: e.currentTarget.value })}
            />
          </FormItem>
          <FormItem style={{ textAlign: 'center' }}>
            <Button onClick={() => this.login()} loading={this.state.loading}
              type="primary" htmlType="submit" style={{ alignSelf: 'stretch' }} className="login-form-button">
              Entrar
            </Button>
          </FormItem>
          <h5 style={{ color: 'red', textAlign: 'center' }}>
            {this.state.feedbackMsg}
          </h5>
        </Form>
      </Layout>);
  }
}

export default connect(
  (state) => ({
    users: state.users,
  }),
  null)(Login);