import React, { Component } from 'react';
import { Button, Icon, Input, Layout, Form } from 'antd';
import Helpers from '../utils/Helpers'
export default class CreditCardNSUInput extends Component {

    constructor(props) {
        super(props);
        this.state = {
            nsu: '',
            nsuOk: true
        }
    }

    getValue = () => {
        return this.state.nsu;
    }

    render() {
        return <div style={{ padding: 15 }}>
                <Form style={{ width: 350, margin: 'auto' }}>
                    <h5>Digite o comprovante gerado pela máquininha POS</h5>
                    <Form.Item style={{width:250,margin:'auto'}}
                        label="NSU / DOC"
                        validateStatus={!this.state.nsuOk ? 'error' : ''}
                        help={!this.state.nsuOk ? 'Campo obrigatório' : ''}
                    >
                        <Input placeholder="••••••" type="text" value={this.state.nsu} onChange={(e) => {
                            this.setState({ nsu: e.target.value })
                            if(this.props.onChange) this.props.onChange(e.target.value);
                        }} />
                    </Form.Item>
                </Form>
        </div>
    }
}