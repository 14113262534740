import React, { Component } from 'react';
import Guid from 'guid'
import { connect } from 'react-redux'
import { Image, List, Header, Label } from 'semantic-ui-react'
import { Row, Col, Card, Menu, Dropdown, Button, Icon, Modal, Input, Layout, Tag, notification } from 'antd';
import Colors from '../constants/Colors'
import Helpers from '../utils/Helpers'
import { getCart, setActiveCartId, setCart, clearAllCarts, newCart, removePaymentFromCart } from '../store/cartsReducer'
import Api from '../api/Api'
import ProductEdit from './ProductEdit'
import ProductRow from './ProductRow'
import DiscountModal from './DiscountModal'
import PaymentModal from './PaymentModal'
import PaymentRow from './PaymentRow';
import CustomerModal from './CustomerModal';
import { PaymentType } from '../constants/Enums';
import WebPOSHelper, { loadWebPOSLoader } from '../utils/webpos.helper'
const confirm = Modal.confirm;
const Content = Layout.Content;
var moment = require('moment');

class CartsHeader extends Component {

    constructor(props) {
        super(props)
        this.state = {
            modalClearAllOpened: false,
            modalClearCartOpened: false,
        }
    }

    showConfirmEraseAllCarts = () => {
        var that = this;
        return confirm({
            title: 'Tem certeza que deseja apagar TODOS os carrinhos abertos?',
            //content: 'Some descriptions',
            onOk() {
                that.props.dispatch(clearAllCarts());
            },
            onCancel() {
                console.log('Cancel');
            },
            okText: 'Sim',
            cancelText: 'Não'
        });
    }

    showConfirmEraseProducts = () => {
        var that = this;
        return confirm({
            title: 'Tem certeza que deseja apagar todos os produtos deste carrinho?',
            //content: 'Some descriptions',
            onOk() {
                if (that.props.activeCart.payments && that.props.activeCart.payments.length > 0) {
                    // there are payments on this cart, are you really sure?
                    confirm({
                        title: 'Atencão, este carrinho tem pagamentos registrados, você tem certeza que deseja apagar todos os produtos e pagamentos deste carrinho?',
                        onOk() {
                            that.props.dispatch(setCart(that.props.activeCartId, { ...(that.props.activeCart), products: {}, payments: [] }));
                            that.setState({ modalClearCartOpened: false })
                        },
                        onCancel() {
                            console.log('Cancel');
                        },
                        okText: 'Sim',
                        cancelText: 'Não'
                    });
                } else {
                    that.props.dispatch(setCart(that.props.activeCartId, { ...(that.props.activeCart), products: {}, payments: [] }));
                    that.setState({ modalClearCartOpened: false })
                }
            },
            onCancel() {
                console.log('Cancel');
            },
            okText: 'Sim',
            cancelText: 'Não'
        });
    }

    cartMenuItemclick = (item) => {
        switch (item.key) {
            case "new": this.props.dispatch(newCart())
            case "clear": this.setState({ modalClearAllOpened: true })
            default: this.props.dispatch(setActiveCartId(item.key));
        }
    }

    renderCartsMenu = () => {
        var that = this;
        var cartsArr = Helpers.ObjToArray(this.props.carts);
        return <Menu onClick={(item) => {
            if (item.key == "new") that.props.dispatch(newCart());
            else if (item.key == "clear") that.showConfirmEraseAllCarts()
            else that.props.dispatch(setActiveCartId(item.key));
        }}>
            <Menu.Item key="new" style={{ color: Colors.themeColor }}><Icon type="plus" /> Novo carrinho</Menu.Item>
            <Menu.Divider />
            {cartsArr && cartsArr.map(x => <Menu.Item key={x.guid}>
                <div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <span style={{ marginRight: 10 }}>{x.name}</span> <span>{Helpers.FloatToReais(x.sum || 0)}</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', fontSize: 11 }}>
                        <span>{x.qtd} {x.qtd == 1 ? 'produto' : 'produtos'}</span> <span>{moment(x.createdAt).format('HH:mm DD/MM')}</span>
                    </div>
                </div>
            </Menu.Item>)}
            <Menu.Divider />
            <Menu.Item key="clear" style={{ color: Colors.red }}><Icon type="delete" /> Limpar todos</Menu.Item>
        </Menu>
    }

    render() {
        var screen = this.props.screenState.screen;
        var rightOpened = this.props.screenState.rightOpened;
        var rowstyle = {
            padding: 15, backgroundColor: '#fff', width: '100%',
            borderTopWidth: screen == 'mobile' ? 4 : 0,
            borderColor: Colors.themeColor, borderTopStyle: 'solid'
        };
        return (
            rightOpened ?
                <Row type="flex" justify="space-around" align="middle" style={rowstyle}>
                    <Col span={2} style={{ textAlign: 'left' }} width={80}>
                        <Button icon="delete" type="dashed" size={'large'}
                            onClick={() => this.showConfirmEraseProducts()} />
                    </Col>
                    <Col span={17}>
                        <Input style={{ width: '100%', height: 42, textAlign: 'center', fontSize: 18, paddingRight: 37 }}
                            onChange={(e, data) => {
                                this.props.dispatch(setCart(this.props.activeCartId, { ...(this.props.activeCart), name: e.target.value }))
                            }}
                            size={"default"} value={this.props.activeCart.name || 'Carrinho'} />
                        <Dropdown trigger={['click']} overlay={this.renderCartsMenu()}>
                            <Button icon="caret-down" size={'large'}
                                style={{ position: 'absolute', right: 1, top: 1, backgroundColor: '#f2f2f2', width: 37, border: 'none' }} />
                        </Dropdown>
                    </Col>
                    {screen == 'mobile' &&
                        <Col span={4} style={{textAlign: 'left'}}>
                            <Button type="primary" className="btnBigIcon" size={'large'}
                                onClick={() => this.props.screenState.toggleScreenFcn()}
                            >
                                <Icon type="left" />
                                <Icon type="shopping-cart" />
                            </Button>
                        </Col>
                    }
                </Row>
                :
                <Row type="flex" justify="space-around" align="middle" style={rowstyle}>
                    <Col span={12}>
                        <Input style={{ width: '100%', height: 42, textAlign: 'center', fontSize: 18, paddingRight: 37 }}
                            onChange={(e, data) => {
                                this.props.dispatch(setCart(this.props.activeCartId, { ...(this.props.activeCart), name: e.target.value }))
                            }}
                            size={"default"} value={this.props.activeCart.name || 'Carrinho'} />
                        <Dropdown trigger={['click']} overlay={this.renderCartsMenu()}>
                            <Button icon="caret-down" size={'large'}
                                style={{ position: 'absolute', right: 1, top: 1, backgroundColor: '#f2f2f2', width: 37, border: 'none' }} />
                        </Dropdown>
                    </Col>
                    <Col span={12} style={{textAlign: 'left', display: 'flex', justifyContent:'space-between', alignItems: 'center'}}>
                        <h3 style={{ marginLeft: 15, marginBottom: 0, display: 'inline-block', lineHeight: '20px' }}>
                            { this.props.activeCartTotal > 0 ? 'Total: ' + Helpers.FloatToReais(this.props.activeCartTotal) : ''}
                        </h3>
                        <Button type="primary" className="btnBigIcon" icon="shopping-cart" size={'large'}
                            onClick={() => this.props.screenState.toggleScreenFcn()}
                        >
                            <Icon type="right" />
                        </Button>
                    </Col>
                </Row>
        );
    }
}

const styles = {
    cartSection: {
        justifyContent: 'space-between', display: 'flex', flexDirection: 'row', alignItems: 'center',
        backgroundColor: Colors.bgColor, padding: 15
    }
}

export default connect(
    (state) => ({
        carts: state.cart.carts,
        activeCartId: state.cart.activeCartId,
        activeCart: getCart(state.cart.carts, state.cart.activeCartId),
        activeCartTotal: getCart(state.cart.carts, state.cart.activeCartId) && getCart(state.cart.carts, state.cart.activeCartId).sum || 0
    }),
    null)(CartsHeader);
