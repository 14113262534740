import React, { Component } from 'react';
import { Button, Icon, Input, Layout, Form } from 'antd';
import Helpers from '../utils/Helpers'
var SimpleCurrencyInput = require('react-simple-currency');

export default class MoneyInput extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: props.value || 0,
            valueRaw: props.value * 100 || 0,
        }
    }

    getValue = () => {
        return this.state.value
    }

    render() {
        return <div style={{ padding: 15 }}>
                <Form style={{ width: 350, margin: 'auto' }}>
                    <Form.Item style={{width:250,margin:'auto'}}
                        label={this.props.label || "Valor"}
                    >
                    <SimpleCurrencyInput
                            className='ant-input ant-input-lg txCenter'
                            value={this.state.valueRaw}
                            precision={2}
                            separator=','
                            delimiter='.'
                            unit='R$'
                            onInputChange={(raw, val) => { 
                                this.setState({ valueRaw: raw, value: raw / 100 }); 
                                if(this.props.onChange) this.props.onChange(raw/100);
                            }}
                        />
                    </Form.Item>
                </Form>
        </div>
    }
}