import store from './../store/store';
import { catalogReducer, setProducts } from './../store/catalogReducer';

import axios from 'axios'
import { setSale } from '../store/salesReducer';
var moment = require('moment');

const CompanyName = 'SOS Chocolate';
//const BaseURL = "https://www.soschocolate.com.br";
const BaseURL = "https://homolog.soschocolate.com.br";
//const BaseURL = "http://localhost:64649";
//const BaseURL = "http://192.168.0.13:64650";
//const BaseURL = "http://10.0.0.100:64650";
const ApiURL = BaseURL + "/api/v1";
const AdminURL = BaseURL + "/admin";

const StoreId = 1;
const SaleOrigins = { Site: 1, App: 2, POS: 3, iFood: 4 }

var Api = {
    CompanyName,
    BaseURL,
    ApiURL,
    AdminURL,
    StoreId,
    SaleOrigins,
    Login: (user, pass) => {
        return axios.post(ApiURL + '/user/login', { email: user, password: pass })
    },
    Products: {
        getProducts: () => {
            return axios.get(ApiURL + '/product/GetForStore/' + StoreId)
                .then(x => {
                    console.log('Setting products:', x.data);
                    store.dispatch(setProducts(x.data));
                }).catch(e => {
                    console.error(e);
                })
        }
    },
    Customers: {
        getCustomer: (document="",telephone="",email="") => {
            return axios.get(ApiURL + '/customer/find?document=' + document + '&telephone=' + telephone + '&email=' + email)
                .then(x => {
                    console.log('Got customer:', x.data);
                    return x.data;
                }).catch(e => {
                    console.error(e);
                })
        }
    },
    Sales: {
        syncSale: (sale) => {
            if(Api.Sales.syncing) return;
            Api.Sales.syncing = true;
            store.dispatch(setSale(sale.guid, { ...sale, loading: true }));
            var sSale = { ...sale, soldDate: moment(sale.soldDate).utc().format() }
            return axios.post(ApiURL + '/sale/save', sale)
              .then(function (response) {
                console.log(response);
                Api.Sales.syncing = false;
                var serverSaleId = response.data.id;
                sale.loading = false;
                sale.id = serverSaleId;
                sale.dateSync = new Date();
                sale.synced = true;
                store.dispatch(setSale(sale.guid,sale));
              })
              .catch(function (error) {
                Api.Sales.syncing = false;
                sale.loading = false;
                store.dispatch(setSale(sale.guid,sale));
                console.log(error);
              });
        }
    }
}
export default Api;