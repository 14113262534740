import React, { Component } from 'react';
import { Layout, Menu, Icon, Tabs, Button } from 'antd';
import { connect } from 'react-redux'
import Colors from '../constants/Colors'
import GlobalStyles from '../constants/GlobalStyles'
import Helpers from '../utils/Helpers'
import SaleDetails from '../components/SaleDetails'
import { PaymentMethod, PaymentMethodBR, PaymentType, EnumSaleStatus } from '../constants/Enums'
import SectionList from 'react-virtualized-sectionlist';
import ResponsiveScreen from './../components/ResponsiveScreen';
var moment = require('moment');
const { Header, Content, Footer, Sider } = Layout;

class Sales extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedSaleId: Object.keys(props.sales).length > 0 && Helpers.ObjToArray(props.sales)[Object.keys(props.sales).length - 1].guid || 0,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ selectedSaleId: this.state.selectedSaleId || Object.keys(nextProps.sales).length > 0 && Helpers.ObjToArray(nextProps.sales)[Object.keys(nextProps.sales).length - 1].guid || 0 })
  }

  renderSalesList = (screenState) => {
    var arrRet = [];
    var sections = this.getSaleSections().map(x => {
      arrRet.push(this.renderHeader(x));
      x.data.map(s => arrRet.push(this.renderSaleRow(s,screenState)));
    });
    return arrRet;
    // sections
    //   .map(x => { 
    //       arrRet.push(this.renderHeader(x));
    //       x.data
    //         .sort((a, b) => moment.utc(b.soldDate).diff(moment.utc(a.soldDate)))
    //         .map(s => arrRet.push(this.renderSaleRow(s)));
    //     });
    //     arrRet = arrRet.sort((a, b) => moment.utc(b.data[0].soldDate).diff(moment.utc(a.data[0].soldDate)))
    // return arrRet;

    // var sales = Helpers.ObjToArray(this.props.sales).sort((a, b) => moment.utc(b.soldDate).diff(moment.utc(a.soldDate)));
    // console.log('sales ordered:', sales);
    // return sales.map(s => {
    //   return this.renderSaleRow(s);
    // })
  }

  renderSaleRow = (s,screenState) => {
    var paymentMethod = s.payments.length == 1 ? s.payments[0].method : 'multiple';
    //console.log('paymentMethod:', paymentMethod);
    return <div className={`clickable ${s.guid == this.state.selectedSaleId ? 'itemSelected' : ''}`} style={{
      padding: 15, borderBottom: '1px solid #eee', minHeight: 60, alignSelf: 'stretch',
      flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', display: 'flex'
    }}
      onClick={() => { 
        this.setState({ selectedSaleId: s.guid });
        if(screenState.screen == 'mobile'){
          screenState.toggleScreenFcn();
        }
      }} key={s.guid}>
      <span>
        {paymentMethod == 'multiple' ?
          <Icon type="appstore-o" />
          : paymentMethod == PaymentMethod.MONEY ?
            <Icon type="wallet" />
            : paymentMethod == PaymentMethod.CREDIT ?
              <Icon type="credit-card" />
              : paymentMethod == PaymentMethod.DEBIT ?
                <Icon type="credit-card" />
                : paymentMethod == PaymentMethod.INSTAGRANA ?
                  <Icon type="qrcode" />
                  :
                  null
        }
        <span style={{ marginLeft: 10 }}>
          {Helpers.FloatToReais(s.sum)}
        </span>
      </span>
      <span>
        {moment(s.soldDate).format('HH:mm')}
        <span style={{ marginLeft: 10 }}>
          {s.id ? <Icon type="check" style={{ color: Colors.success }} /> :
            s.loading ? <Icon type="loading" /> :
              <Icon type="clock-circle-o" />
          }
        </span>
      </span>
    </div>;
  }
  dateSortFcn = (b, a) => {
    //console.log('comparing',a,b);

    if (a.isAfter(b))
      return 1;
    if (a.isBefore(b))
      return -1;
    return 0;
  };
  getSaleSections = () => {
    var sales = Helpers.ObjToArray(this.props.sales).map(x => ({ ...x, dateStr: moment(x.soldDate).format('DD/MM/YY') }));//.sort((a, b) => a.soldDate < b.soldDate);
    console.log('SSSSales:', sales)
    if (sales.length == 0) return sales;
    var groupedSales = Helpers.GroupBy(sales, 'dateStr');
    var dSales = Object.keys(groupedSales).map(x => {
      var gSales = groupedSales[x];
      var confirmedSales = gSales.filter(x => x.status == EnumSaleStatus.Confirmed);
      return {
        title: `${x} (${gSales.length})`, // ${Helpers.FloatToReais(gSales.reduce((a,b) => { return a + b.sum },0))}`, 
        totalSum: `${confirmedSales.reduce((a, b) => { return a + b.sum }, 0)}`,
        allPmtsSum: `${confirmedSales.reduce((a, b) => { return a + b.payments.map(p => p.value).reduce((x, y) => x + y, 0) }, 0)}`,
        moneySum: `${confirmedSales.reduce((a, b) => {
          return a + b.payments.filter(p => p.method == 'money')
            .map(p => p.value)
            .reduce((x, y) => x + y, 0)
        }, 0)}`,
        otherPmtsSum: `${confirmedSales.reduce((a, b) => {
          return a + b.payments.filter(p => p.method != 'money')
            .map(p => p.value)
            .reduce((x, y) => x + y, 0)
        }, 0)}`,
        refDate: gSales[0].soldDate,
        data: gSales.sort((a, b) => this.dateSortFcn(moment(a.soldDate), moment(b.soldDate)))
      }
    })
      .sort((a, b) => this.dateSortFcn(moment(a.refDate), moment(b.refDate)))
    // dSales.map(x=> { 
    //   //x.sum = x.data.reduce((a,b) => { return a + b.sum },0);
    //   return x; 
    // })
    console.log('dddSales:', dSales);
    dSales.map(x => console.log('dSale=>', x.refDate));
    return dSales;
  }
  renderHeader = ({ title, moneySum, totalSum, otherPmtsSum, allPmtsSum }) => {
    return (
      <div key={title} className="list--header">
        <h4 onDoubleClick={() => alert(`totalProductsSum: ${Helpers.FloatToReais(totalSum)} | totalMoney: ${Helpers.FloatToReais(moneySum)} | otherPaymentsSum: ${Helpers.FloatToReais(otherPmtsSum)} | allPaymentsSum: ${Helpers.FloatToReais(allPmtsSum)}`)}>
          {title} <small style={{ fontSize: 12 }}>
            <Icon type="wallet" /> {Helpers.FloatToReais(moneySum - (allPmtsSum - totalSum) || 0)}</small>
        </h4>
      </div>
    );
  };
  // renderRow = ({ item, sectionIndex, rowIndex, key, style, isScrolling, isVisible, parent }) => {
  //   return (this.renderSaleRow(item));
  // };

  render() {
    return <Layout style={{ flexDirection: 'row' }}>
      <ResponsiveScreen style={{ flex: 1 }}
        left={(screenState) => (
          <Content width={'auto'} style={{
            ...(GlobalStyles.leftCol), flex: 3, justifyContent: 'space-between',
            maxWidth: screenState.screen == 'mobile' ? 'auto' : '100%',
            maxHeight: screenState.screen == 'mobile' ? 'calc(100vh - 125px)' : 'auto',
          }}>
            <div width={'auto'} style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'flex-start', alignSelf: 'stretch', overflowY: 'auto' }}>
              {this.renderSalesList(screenState)}
            </div>

            {/* LOADING FOOTER BAR */}
            {
              Helpers.Any(Helpers.ObjToArray(this.props.sales), 'loading')
              &&
              <div style={{
                height: 60, borderTop: "3px solid", borderTopColor: Colors.themeColor, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between',
                padding: 15, alignSelf: 'stretch', background: '#fff'
              }}>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <Icon type="cloud-upload-o" style={{ fontSize: 29, color: Colors.themeColor }} />
                  <h3 style={{ display: 'inline-block', margin: 0, marginLeft: 10, color: Colors.themeColor }}>Sincronizando vendas...</h3>
                </span>
                <Icon type="loading" style={{ fontSize: 26 }} />
              </div>
            }
          </Content>)}
        leftStyle={{ flex: 3 }}
        right={(screenState) => (
          <Content style={{ flex: 7 }}>
            <Content style={{ flex: 1, maxWidth: screenState.screen == 'mobile' ? '100vw' : '100%', maxHeight: screenState.screen == 'mobile' ? 'calc(100vh - 125px)' : 'calc(100vh - 65px)',
              overflow: 'auto', background: '#fff', padding: 25, alignSelf: 'stretch', overflowY: 'auto' }}>
              {(this.state.selectedSaleId) && <SaleDetails saleId={this.state.selectedSaleId} screenState={screenState} />}
              {(!this.state.selectedSaleId) && <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}><h2>Nenhuma venda registrada</h2></div>}
            </Content>
          </Content>
        )}
        rightStyle={{ flex: 7 }}
      />
    </Layout>


    //var salesSections = this.getSaleSections();
    return <Layout style={{ minWidth: 800, flexDirection: 'row', flex: 1, alignSelf: 'stretch' }}>
      <Content width={'auto'} style={{ ...(GlobalStyles.leftCol), flex: 3, justifyContent: 'space-between' }}>
        {/* { salesSections.length > 0 &&
        <SectionList
        width={300}
        height={100}
        style={{flex:1, display:'flex',alignSelf:'stretch'}}
        sections={salesSections}
        sectionHeaderRenderer={this.renderHeader}
        sectionHeaderHeight={30}
        rowHeight={30}
        rowRenderer={this.renderRow} />
      } */}
        <div width={'auto'} style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'flex-start', alignSelf: 'stretch', overflowY: 'auto' }}>
          {this.renderSalesList()}
        </div>

        {/* LOADING FOOTER BAR */}
        {
          Helpers.Any(Helpers.ObjToArray(this.props.sales), 'loading')
          &&
          <div style={{
            height: 60, borderTop: "3px solid", borderTopColor: Colors.themeColor, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between',
            padding: 15, alignSelf: 'stretch', background: '#fff'
          }}>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <Icon type="cloud-upload-o" style={{ fontSize: 29, color: Colors.themeColor }} />
              <h3 style={{ display: 'inline-block', margin: 0, marginLeft: 10, color: Colors.themeColor }}>Sincronizando vendas...</h3>
            </span>
            <Icon type="loading" style={{ fontSize: 26 }} />
          </div>
        }


      </Content>
      <Content style={{ flex: 7 }}>
        <Content style={{ flex: 1, maxWidth: '100%', overflow: 'auto', background: '#fff', padding: 25, alignSelf: 'stretch', overflowY: 'auto', maxHeight: 'calc(100vh - 65px)' }}>
          {(this.state.selectedSaleId) && <SaleDetails saleId={this.state.selectedSaleId} />}
          {(!this.state.selectedSaleId) && <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}><h2>Nenhuma venda registrada</h2></div>}
        </Content>
      </Content>
    </Layout>
  }
}

export default connect(
  (state) => ({
    sales: state.sales,
  }),
  null)(Sales);
