import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Button, Icon, Modal, Input, Layout, Form } from 'antd';
import Colors from '../constants/Colors'
import Helpers from '../utils/Helpers'
import { setCart, getCart } from '../store/cartsReducer'
import Api from '../api/Api'
import DiscountInput from './DiscountInput'

class DiscountModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modalDiscountOpened: false,
            DiscountOk: true,
            DiscountError: '',
            discount: props.discount || null
        }
        if (props.myRef) props.myRef(this);
    }
    componentWillReceiveProps(nextProps){
        this.setState({discount: nextProps.discount});
    }
    addDiscount = () => {
        this.setState({ modalDiscountOpened: true });
    }
    onSetDiscountOk = () => {
        var newCart = {...(this.props.activeCart), discount: this.state.discount}
        this.props.dispatch(setCart(this.props.activeCart.guid,newCart));
        this.setState({ modalDiscountOpened: false })
    }
    onDiscountCancel = () => {
        this.setState({ modalDiscountOpened: false })
    }
    onDiscountRemove = () => {
        var newCart = {...(this.props.activeCart), discount: null}
        this.props.dispatch(setCart(this.props.activeCart.guid,newCart));
        this.setState({ modalDiscountOpened: false })
    }
    setDiscount = (discount) => {
        this.setState({ discount });
    }
    render() {
        var prod = this.state.prod;
        return <Modal
            visible={this.state.modalDiscountOpened}
            title="Desconto no carrinho"
            onOk={this.onSetDiscountOk}
            onCancel={this.onDiscountCancel}
            footer={<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Button key="remove" size="large" type="danger" onClick={this.onDiscountRemove} icon="delete">Remover</Button>
                {/* <Button key="cancel" size="large" onClick={this.onEditProductCancel}>Cancelar</Button> */}
                <Button key="submit" size="large" type="primary" onClick={this.onSetDiscountOk} icon="shopping-cart">Aplicar</Button>
            </div>}
        >
            <Form>
                <Form.Item style={{ flex: 1, marginRight: 20 }}
                    label="Desconto"
                    validateStatus={!this.state.DiscountOk ? 'error' : ''}
                    help={this.state.DiscountError || ''}
                >
                    <DiscountInput value={this.state.discount} ref={(me) => this.discountInput = me} onChange={this.setDiscount} />
                </Form.Item>
            </Form>
        </Modal>
    }
}
export default connect(
    (state) => ({
        activeCart: getCart(state.cart.carts, state.cart.activeCartId),
    }),
    null)(DiscountModal);