import React, { Component } from 'react';
import Responsive from 'react-responsive';

const Desktop = props => <Responsive {...props} minWidth={992} />;
const Tablet = props => <Responsive {...props} minWidth={768} maxWidth={991} />;
const Mobile = props => <Responsive {...props} maxWidth={767} />;
const Default = props => <Responsive {...props} minWidth={768} />;

export default class ResponsiveScreen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            opened: false
        }
    }

    render() {
        var toggleScreenFcn = () => this.setState({ opened: !this.state.opened });
        return <div style={{ display: 'flex', flex: 1 }}>
            {/* <Desktop>Desktop or laptop</Desktop>
            <Tablet>Tablet</Tablet> */}

            <Mobile>
                <div style={{ flexDirection: 'column', alignItems: 'stretch', display: 'flex', flex: 1 }}>
                    {/* LEFT SCREEN MOBILE */}
                    <div style={{ display: 'flex', flex: 1, ...this.props.leftStyle }}>
                        {this.props.left({screen: 'mobile', rightOpened: this.state.opened, toggleScreenFcn: toggleScreenFcn })}
                    </div>
                    {/* RIGHT SCREEN MOBILE */}
                    {this.props.bottomSheet ?
                        <div style={{
                                display: 'flex', position: 'absolute', zIndex: 999,
                                width: '100vw', //height: 80, background: '#4444ff',
                                top: this.state.opened ? 64 : 'auto',
                                bottom: this.state.opened ? 'auto' : 64,
                            }}>
                            {this.props.bottomSheet({screen: 'mobile', rightOpened: this.state.opened, toggleScreenFcn: toggleScreenFcn })}
                        </div>
                        : null
                    }
                    <div style={{
                        display: 'flex', flex: 1,
                        position: 'absolute', width: '100%',
                        left: this.state.opened ? 0 : '100vw',
                        bottom: this.state.opened ? 64 : 'auto',
                        overflow: this.state.opened ? 'auto' : 'hidden',
                        transition: 'all 0.2s', transitionTimingFunction: 'ease-out',
                        ...this.props.rightStyle
                    }}>
                        {this.props.right({screen: 'mobile', rightOpened: this.state.opened, toggleScreenFcn: toggleScreenFcn })}
                    </div>
                </div>
            </Mobile>
            <Default>
                <div style={{ flexDirection: 'row', alignItems: 'stretch', display: 'flex', flex: 1 }}>
                    {/* LEFT SCREEN DEFAULT */}
                    <div style={{ display: 'flex', flex: 1, ...this.props.leftStyle }}>
                        {this.props.left({screen: 'desktop', rightOpened: this.state.opened })}
                    </div>
                    {/* RIGHT SCREEN DEFAULT */}
                    <div style={{ display: 'flex', flex: 1, ...this.props.rightStyle }}>
                        {this.props.right({screen: 'desktop', rightOpened: this.state.opened })}
                    </div>
                </div>
            </Default>
        </div>
    }
}