import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Row, Col, Card, Menu, Dropdown, Button, Icon, Modal, Input, Layout, Tag, Switch, Popconfirm, message, notification } from 'antd';
import Colors from '../constants/Colors'
import Helpers from '../utils/Helpers'
import { getSale, setNotaData, setCustomerData, cancelSale, setSale } from '../store/salesReducer'
import { PaymentMethod, PaymentMethodBR, PaymentType, EnumSaleStatusBR, EnumSaleStatus } from '../constants/Enums'
import Api from '../api/Api'
import PaymentRow from './PaymentRow'
import ProductRow from './ProductRow'
import CustomerEdit from './CustomerEdit'
import { loadWebPOSLoader, transformNotaData } from '../utils/webpos.helper'
var moment = require('moment');

class SaleDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loadingNota: false,
            autoPrintNota: true,
            notaOnline: false
        }
    }
    doPrintAll = async () => {
        // just print nota
        return this.doNotaPrint();

        // for get this  ---V
        // is TEF? print card receipt first!
        if (this.props.sale.payments.filter(p => p.type == PaymentType.TEF && p.data.CupomReduzido)) {
            var pmts = this.props.sale.payments.filter(p => p.type == PaymentType.TEF && p.data.CupomReduzido);
            var i = 1;
            var that = this;
            pmts.forEach(p => {
                setTimeout(() => {
                    that.printPayment(p, false);
                    //webpos.print(p.data.CupomReduzido,true,true, (ok) => {}, (err) => {});
                }, i * 1000);
                i++;
            });
            setTimeout(() => {
                that.doNotaPrint();
            }, i * 1000 + 2000);
        } else {
            this.doNotaPrint();
        }
    }

    printPayment = async (pmt, fullCut = true) => {
        const webpos = await loadWebPOSLoader();
        var tx = "Pagamento em cartão\n " + pmt.data.CupomCliente.replaceAll('"', '').replaceAll('\r', '') + '\n------------------------------------------';
        webpos.print(tx, true, fullCut, (ok) => { }, (err) => { });
    }

    printNextTefPaymentOrNota = () => new Promise(async (resolve, reject) => {

    })

    doNotaPrint = () => {
        if (this.props.sale.nf) {
            this.reprintNota();
        } else {
            this.emitirNota();
        }
    }
    enviarNota = (notaData) => new Promise(async (resolve, reject) => {
        const webpos = await loadWebPOSLoader()
        // webpos.nota(NFCeData, AutoPrint, OpenFile)
        webpos.nota(notaData, this.state.notaOnline, false, this.state.autoPrintNota, false, resolve, reject)
    })
    emitirNota = async () => {
        try {
            this.setState({ loadingNota: true });
            const notaData = transformNotaData(this.props.sale);
            var thatSale = this.props.sale;
            console.log('notaData:', JSON.stringify(notaData));
            const x = await this.enviarNota(notaData).then(x => {
                var nfce = {
                    RetornoEnvioNota: x.nf.retornoEnvioNota,
                    NFe: x.nf.nfe,
                    PathPDF: x.nf.pathPdf,
                    XML: x.nf.xml
                };
                console.log('Received NFCe return: ', nfce);
                this.props.dispatch(setNotaData(thatSale.guid, nfce)).then(() => {
                    // só pra syncar a venda local com dados da nfe na api aqui embaixo:
                    thatSale.nf = nfce;
                    Api.Sales.syncSale(thatSale);
                    // fim do sync
                    this.setState({ loadingNota: false }, () => {
                        this.props.onCloseModal && this.props.onCloseModal();
                    });
                });
            }).catch(e => {
                this.setState({ loadingNota: false });
            })
        } catch (error) {
            this.setState({ loadingNota: false });
            console.warn(error);
        }
    }
    _reprintNota = async () => new Promise(async (resolve, reject) => {
        const webpos = await loadWebPOSLoader();
        var nfe = this.props.sale.nf.XML || this.props.sale.nf.RetornoEnvioNota.XmlNF;
        //webpos.printNFe(nfe, resolve, reject);
        webpos.printNFeNativo(nfe, this.props.sale.nf.RetornoEnvioNota.XmlRecebido, resolve, reject);
    })
    reprintNota = async () => {
        try {
            this.setState({ loadingNota: true });
            const x = await this._reprintNota().then(x => {
                this.setState({ loadingNota: false });
            }).catch(e => {
                this.setState({ loadingNota: false });
            })
        } catch (error) {
            console.warn(error);
        }
    }
    setCustomer = (customer) => {
        console.log('dispatch setCustomer:', customer);
        this.props.dispatch(setCustomerData(this.props.sale.guid, customer));
    }

    forceSyncSale = () => {
        this.props.dispatch(setNotaData(this.props.sale.guid, {})).then(() => {
            Api.Sales.syncSale(this.props.sale);
        });
    }

    deleteNF = () => {
        this.props.dispatch(setNotaData(this.props.sale.guid, null));
    }

    cancelSyncSale = () => {
        this.props.dispatch(setSale(this.props.sale.guid, { ...(this.props.sale), loading: false }));
    }

    removePayment = async (pmt) => {
        if (pmt.type == PaymentType.TEF) {
            const webpos = await loadWebPOSLoader();
            webpos.transactions.cancel(pmt.data.NumeroControle,
                (aprov,transac) => {
                    console.log('retCancelamento', JSON.stringify(aprov), JSON.stringify(transac));
                    notification['success']({
                        message: 'Transação cancelada com sucesso!',
                        description: 'O valor desta transação foi estornado no cartão! =)',
                        duration: 8
                    });
                    var imprime = window.confirm('Deseja imprimir o comprovante do cancelamento?');
                    if(imprime){
                        var tx = aprov.CupomCliente.replaceAll('"', '').replaceAll('\r', '') + '\n------------------------------------------';
                        webpos.print(tx, true, true, (ok) => { }, (err) => { });
                    }
                    // nao remove pagamento com a venda ja feita:
                    // this.props.dispatch(removePaymentFromCart(pmt.id, this.props.activeCart.guid));
                },
                () => {
                    //console.error('Falha no pagamento!')
                    notification['error']({
                        message: 'Transação não cancelada!',
                        description: 'Ocorreu um erro ao tentar cancelar esta transação! =(',
                        duration: 8
                    });
                }
            )
        } else {
            // nao remove pagamento com a venda ja feita
        }
    }

    doCancelSale = () => {
        if (this.props.sale.nf) {
            notification['error']({
                message: 'Ops, não é possível cancelar esta venda',
                description: 'Esta venda já possui uma Nota Fiscal gerada, portanto não é possível apagá-la do sistema. =(',
                duration: '7'
            });
        } else {
            if (this.props.sale.payments.filter(p => p.method == PaymentMethod.MONEY).length == this.props.sale.payments.length) {
                // all payments are MONEY
                console.log('Canceling sale!')
                this.props.dispatch(cancelSale(this.props.sale.guid)).then(() => {
                    Api.Sales.syncSale(this.props.sale);
                });
            } else {
                notification['error']({
                    message: 'Ops, não é possível cancelar esta venda',
                    description: 'Somente vendas em dinheiro podem ser canceladas. =(',
                    duration: '7'
                });
            }
        }
    }

    render() {
        var sale = this.props.sale;
        var payments = sale.payments;
        var sumPayments = !sale.payments ? 0 : sale.payments.map(p => p.value).reduce((x, y) => x + y, 0);
        var products = sale.products;
        var sumProducts = !sale.products ? 0 : sale.products.map(p => p.Price).reduce((x, y) => x + y, 0);
        var customer = sale.customer || {};
        console.log('render SaleDetails for sale:', sale, '==> CUSTOMER:', customer);
        var nf = sale.nf;
        return (
            <div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 0, marginBottom: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'flex-start' }}>
                        <h1 style={{display:'flex', alignItems: 'center'}}>
                        {this.props.screenState && this.props.screenState.screen == 'mobile' ? 
                            <Button type="primary" style={{marginRight: 10}} onClick={() => {
                                this.props.screenState.toggleScreenFcn();
                            }}>
                                <Icon type="left" />
                            </Button>
                            :null}
                            Venda {sale.id ? `#${sale.id}` : ''}
                        </h1>
                        <h2 style={{ color: Colors.themeColor, display: 'flex', marginTop: 0 }}>Total {Helpers.FloatToReais(sale.sum)}</h2>
                        {sale.User && <h4 style={{ marginTop: 0, color: Colors.secondaryTextColor }}><Icon type="user" /> Caixa: {sale.User.name}</h4>}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'flex-start', alignItems: 'flex-end' }}>
                        <h4><Icon type="login"
                            style={{ color: sale.status == EnumSaleStatus.Canceled || sale.status == EnumSaleStatus.Denied ? Colors.red : Colors.themeColor }}
                        /> Status: {EnumSaleStatusBR[sale.status]}</h4>
                        <h4 style={{ color: Colors.secondaryTextColor, marginTop: 0 }}><Icon type="check" style={{ color: Colors.secondaryTextColor }} /> Vendido: {moment(sale.soldDate).format('DD/MM HH:mm:ss')}</h4>
                        {sale.dateSync ?
                            <h4 style={{ color: Colors.secondaryTextColor, marginTop: 0 }}>
                                <Icon type="check" style={{ color: Colors.success }} /> Sincronizado: {moment(sale.dateSync).format('DD/MM HH:mm:ss')}</h4>
                            :
                            <h4 style={{ color: Colors.secondaryTextColor, marginTop: 0 }}>
                                {sale.loading ? <Icon type="loading" /> : <Icon type="clock-circle-o" style={{ color: Colors.secondaryTextColor }} />
                                } Aguardando {!sale.nf ? 'emissão da Nota Fiscal' : 'sincronização...'}
                            </h4>
                        }
                        {!sale.dateSync && !sale.nf && <Button size="small" onClick={this.forceSyncSale}>Forçar sicronização</Button>}
                        {sale.loading && <Button size="small" onClick={this.cancelSyncSale}>Cancelar sicronização</Button>}
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 0, marginBottom: 30 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'flex-start', alignItems: 'flex-end' }}>
                    </div>
                </div>
                <div className="codebox">
                    <span className="codebox-title">Pagamentos:</span>
                    <div>
                        {payments && payments.map((p, i) => (
                            <PaymentRow key={i} payment={p} noBorder={(i + 1) == payments.length} editable={false} 
                                onRemovePayment={() => this.removePayment(p)} hideDetails={this.props.screenState.screen == 'mobile'}
                                onClick={p.type == PaymentType.TEF && p.data.CupomCliente ? () => this.printPayment(p, true) : null} />
                        ))}
                        {sumPayments > sale.sum &&
                            <h5 style={{ display: 'flex', marginTop: 15, borderTop: '1px solid ' + Colors.lines, paddingTop: 15, justifyContent: 'flex-end' }}>
                                Troco: {Helpers.FloatToReais(sumPayments - sale.sum)}
                            </h5>
                        }
                        {sale.discount &&
                            <h5 style={{ display: 'flex', marginTop: 15, borderTop: '1px solid ' + Colors.lines, paddingTop: 15, justifyContent: 'flex-end' }}>
                                Desconto: {sale.discount.type == 'percent'
                                    ? (sale.discount.value + '% (' + Helpers.FloatToReais(sale.discount.value * 0.01 * sumProducts) + ')')
                                    : Helpers.FloatToReais(sale.discount.value)}
                            </h5>
                        }
                    </div>
                </div>
                <div className="codebox">
                    <span className="codebox-title">Produtos:</span>
                    <div>
                        {products && products.map((p, i) => (<ProductRow key={i} product={p} noBorder={(i + 1) == products.length} />))}
                    </div>
                </div>
                <div className="codebox">
                    <span className="codebox-title">Cliente:</span>
                    <CustomerEdit customer={customer} onChangeCustomer={(customer) => this.setCustomer(customer)}
                        readOnly={nf != null || sale.dateSync} showCPFTip={nf == null && !sale.dateSync && (!customer || !customer.document)} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 25 }}>
                    <Popconfirm title="Tem certeza que deseja cancelar esta venda?" onConfirm={() => this.doCancelSale()} onCancel={() => { }} okText="Sim" cancelText="Não">
                        <Button type='danger' size="large" className="btn-huge" icon="delete">
                            Cancelar
                    </Button>
                    </Popconfirm>
                    {/* <Button type='primary' size="large" className="btn-huge" icon="printer" onClick={() => { }}>
                        Imprimir Recibo
                    </Button> */}
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                        <Button type='primary' size="large" className="btn-huge" icon="printer"
                            loading={this.state.loadingNota}
                            onClick={() => { this.doPrintAll() }}>
                            {nf ? 'Reimprimir Nota Fiscal' : 'Emitir Nota Fiscal'}
                        </Button>
                        {!nf ? <div>
                            <span style={{ marginTop: 10, fontSize: 12, display: 'block', textAlign: 'right' }}>
                                Imprimir automaticamente <Switch onChange={val => { this.setState({ autoPrintNota: val }) }} size="small"
                                    defaultChecked={this.state.autoPrintNota} />
                            </span>
                            <span style={{ marginTop: 10, fontSize: 12, display: 'block', textAlign: 'right' }}>
                                Gerar Online <Switch onChange={val => { this.setState({ notaOnline: val }) }} size="small"
                                    defaultChecked={this.state.notaOnline} />
                            </span>
                        </div> :
                            [
                                <div>
                                    <span style={{ marginRight: 20, fontSize: 12 }}>
                                        {nf.NFe && nf.NFe.infNFeSupl && nf.NFe.infNFeSupl.qrCode &&
                                            <Popconfirm title="Tem certeza que deseja APAGAR a nota fiscal desta venda?" onConfirm={() => this.deleteNF()} onCancel={() => { }} okText="Sim, vou reemitir" cancelText="Não">
                                                <a style={{ color: Colors.red }}>Apagar Nota fiscal</a>
                                            </Popconfirm>
                                        }
                                    </span>
                                    <span style={{ marginTop: 10, fontSize: 12 }}>
                                        {nf.NFe && nf.NFe.infNFeSupl && nf.NFe.infNFeSupl.qrCode &&
                                            <a target="_blank" href={nf.NFe.infNFeSupl.qrCode}>Ver nota online</a>}
                                    </span>
                                </div>
                            ]
                        }
                    </div>
                </div>
            </div>);
    }
}

export default connect(
    (state, ownProps) => ({
        sale: getSale(state.sales, ownProps.saleId),
    }),
    null)(SaleDetails);
