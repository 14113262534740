import React, { Component } from 'react';
import { Image, Segment, Label } from 'semantic-ui-react'
//import Colors from '../constants/Colors'
import Helpers from '../utils/Helpers'
import Api from '../api/Api'
import LazyLoad from 'react-lazyload';
import Colors from '../constants/Colors';
const isImageUrl = require('is-image-url');

export default class Product extends Component {
    render() {
        var prod = this.props.Product;
        var hasImg = isImageUrl(prod.PhotoURL);    
        // iniciais do nome do produto pra qdo nao tem imagem
        var prodNameArr = prod.Name.split(' ');
        var prodInitials = prodNameArr.length > 1 ? prodNameArr[0][0] + prodNameArr[1][0] : prodNameArr[0][0] + prodNameArr[0][1];
        return <div style={{
            padding: 0, display: 'inline-block', width: 160, maxWidth: 160, height: 200, maxHeight: 200, borderRadius: 5,
            boxShadow: '0px 0px 1px #ccc', backgroundColor: '#fff', margin: 10, marginTop: 0
        }}>
            <a onClick={this.props.onClick} style={{
                cursor: 'pointer', position: 'relative',
                display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center'
            }}>
                {/* PRICE */}
                {prod.Price ?
                    <b style={{
                        position: 'absolute', top: 5, right: 0, border: '1px solid ' + Colors.themeColor, borderRight: 'none', background: '#fff',
                        display: 'inline-block', padding: '2px 6px'
                    }}>{Helpers.FloatToReais(prod.Price)}</b> : null
                }
                {/* inStock? */}
                {prod.QtyInStock <= 0 && prod.TrackStock ?
                    <b style={{
                        position: 'absolute', top: 30, right: 0, border: '1px solid ' + Colors.red, color: Colors.red, borderRight: 'none',
                        background: '#fff', display: 'inline-block', padding: '2px 6px', fontSize: 12
                    }}>esgotado</b> : null
                }

                {/* SITE SALE */}
                {prod.SiteSale ?
                    <b style={{
                        position: 'absolute', top: 5, left: 0, border: '1px solid ' + Colors.themeColor, color: '#fff', borderLeft: 'none',
                        background: Colors.themeColor, display: 'inline-block', padding: '2px 6px', fontSize: 12
                    }}>Preço Site</b> : null
                }

                {/* <LazyLoad height={154}> */}
                <div style={{
                    width: 150, height: 150, textAlign: 'center', backgroundImage: `url('${prod.PhotoURL}')`, display: 'flex',
                    backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize: 'contain', margin: 4
                }}>
                    {!hasImg?<span style={{alignSelf:'center',width:'100%',textAlign:'center',fontSize:'1.5em',color:'#93ccf5'}}>{prod.Name}</span>:null}
                    {/* <img src={prod.PhotoURL} style={{ maxWidth: 154, maxHeight: 154 }} /> */}
                </div>
                {/* </LazyLoad> */}

                

                {/* NAME */}
                <div style={{
                    fontSize: 14, borderTop: '1px solid ' + Colors.lines, width: '100%', color: Colors.secondaryTextColor, textAlign: 'center',
                    flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', display: 'flex', fontWeight: 'bold', overflowY: 'hidden',
                    maxHeight: 40
                }}>{prod.Name}</div>
            </a>
        </div>
    }
}