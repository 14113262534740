import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import { Provider } from 'react-redux';
//import { Menu, Icon } from 'semantic-ui-react'
import { BrowserRouter, Route, Link, withRouter } from 'react-router-dom'
import Network from 'react-network'
import { connect } from 'react-redux'

import { Icon as IconKit } from 'react-icons-kit';
import { pen_3 } from 'react-icons-kit/ikons/pen_3';
import { list_2 } from 'react-icons-kit/ikons/list_2';
import { version } from '../package.json'

import Login from './screens/Login';
import Sales from './screens/Sales';
import PDV from './screens/PDV';
import Admin from './screens/Admin';
import Products from './screens/Products';
import Settings from './screens/Settings';
import Colors from './constants/Colors';
import store from './store/store';
import { Layout, Menu, Icon, Badge, Dropdown, Button, message } from 'antd';
import Helpers from './utils/Helpers';
import { setInterval } from 'timers';
import Api from './api/Api';
import { setSale } from './store/salesReducer';
import { setUserAuth } from './store/authReducer';
var moment = require('moment');
const { Header, Content, Footer, Sider } = Layout;
class App extends Component {
  componentDidMount(){
    if(window){
      window.globalMessage = message;
    }
  }
  render() {
    return (
      <Provider store={store}>
        <ConnectedMainApp />
      </Provider>
    );
  }
}

export default App;

class MainApp extends React.PureComponent {
  state = {
    collapsed: true,
  };
  onCollapse = (collapsed) => {
    console.log(collapsed);
    this.setState({ collapsed });
  }
  getTopMenu = () => {
    return <Menu>
      <Menu.Item key="0">
        {this.props.auth.userAuth.user}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="-1">
        <a onClick={() => {
          console.log('logging off...');
          this.props.dispatch(setUserAuth({}))
        }}>Logoff</a>
      </Menu.Item>
    </Menu>
  }
  render() {
    var auth = this.props.auth.userAuth;
    return auth && auth.token && auth.token && new Date(auth.expires) > new Date() ?
      <BrowserRouter>
        <Layout>
          <Sider
            trigger={null}
            collapsible
            collapsed={this.state.collapsed}
            onCollapse={this.onCollapse}
          //style={{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0, borderRight: '1px solid #000' }}>
          >
            <div className="logo" />
            <AppMenu />
          </Sider>
          <Layout style={{ marginLeft: 0, flex: 1 }}>
            <Header style={{
              background: '#fff', padding: 0, position: 'fixed', alignSelf: 'stretch', flex: 1,
              zIndex: 8, width: `calc(100% - ${this.state.collapsed ? '64px' : '200px'})`, boxShadow: '0 0 8px #e3e3e3'
            }}>
              {/* <Icon
                  className="trigger"
                  type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                  onClick={() => this.setState({ collapsed: !this.state.collapsed })}
                /> */}
              <div style={{display: 'inline-block', height: 45, verticalAlign: 'middle'}}>
                <h2 style={{ marginLeft: 15, display: 'inline-block', lineHeight: '20px' }}>{Api.CompanyName}<br/>
                  <span style={{fontSize:11, fontWeight: 'normal'}}>SisPDV {version} {Api.ApiURL.indexOf('homolog')>-1?' HOMOLOG!':''}</span>
                </h2>
              </div>
              <Dropdown overlay={this.getTopMenu()} trigger={['click']}>
                <Button style={{ position: 'absolute', right: 20, top: 20 }}>
                  <Icon type="user" theme="outlined" style={{fontSize: 14}} /> {auth.name} <Icon type="down" />
                </Button>
              </Dropdown>
            </Header>
            <Content style={{ flex: 1, display: 'flex', maxHeight: 'calc(100vh - 64px)', marginTop: 61 }}>
              <Route exact path='/' component={PDV} />
              <Route exact path='/pdv' component={PDV} />
              <Route exact path='/sales' component={Sales} />
              <Route exact path='/products' component={Products} />
              <Route exact path='/admin' component={Admin} />
              <Route exact path='/settings' component={Settings} />
            </Content>
          </Layout>
        </Layout>
      </BrowserRouter>
      :
      <Login />
  }
}

const ConnectedMainApp = connect(
  (state) => ({
    auth: state.auth,
  }),
  null)(MainApp);



class _AppMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      route: "/"
    }
  }
  render() {
    var loc = this.props.location.pathname;
    console.log('LOCATION -> ', loc);
    return <Menu theme="dark" mode="inline" defaultSelectedKeys={[loc]} onClick={(item, key, path) => {
      if (item.key != '#') this.props.history.push(item.key, { some: 'state' })
    }}
      style={{ flexDirection: 'column' }}
    >
      <Menu.Item key="/pdv">
        <Icon type="shop" />
        <span className="nav-text">PDV</span>
      </Menu.Item>
      <Menu.Item key="/sales">
        {/* <Network
          onChange={({ online }) => {
            //if (online && window.cornify_add) {
            //  window.cornify_add()
            //}
          }}
          render={({ online }) => <Badge count={12} style={{ backgroundColor: online ? '#87d068' : '#f04134' }}><Icon type="bars" /></Badge>} /> */}
        <SalesMenuIcon />
        <span className="nav-text">Vendas</span>
      </Menu.Item>
      <Menu.Item key="/products">
        <Icon type="edit" />
        <span className="nav-text">Estoque</span>
      </Menu.Item>
      <Menu.Item key="/admin">
        <Icon type="solution" />
        <span className="nav-text">Admin</span>
      </Menu.Item>
      <Menu.Item key="/settings">
        <Icon type="setting" />
        <span className="nav-text">Configurações</span>
      </Menu.Item>
    </Menu>
  }
}
const AppMenu = withRouter(_AppMenu)


// Unsynced sales count component
class _SalesMenuIcon extends React.Component {

  componentDidMount() {
    // is there a sale still loading (from last program close)?
    var sales = Helpers.ObjToArray(this.props.sales);
    if (sales.filter(x => x.loading).length > 0) {
      var syncingSale = sales.filter(x => x.loading)[0];
      this.props.dispatch(setSale(syncingSale.guid, { ...syncingSale, loading: false }));
    }
    this.syncSaleInterval = setInterval(() => { this.checkNeedToSyncSale() }, 30 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.syncSaleInterval);
  }

  // sales to sync are those without ID and no Customer Receipt generated
  saleNeedsToSyncCheckFunction = x => x.id == null && x.nf;

  checkNeedToSyncSale() {
    var sales = Helpers.ObjToArray(this.props.sales);
    // if is syncing any sale already, return
    if (sales.filter(x => x.loading).length > 0) return;
    if (Helpers.Count(sales, this.saleNeedsToSyncCheckFunction) > 0)
      this.syncNextSale();
  }

  syncNextSale = () => {
    var nextSale = Helpers.ObjToArray(this.props.sales).filter(this.saleNeedsToSyncCheckFunction).sort((a, b) => moment(a.soldDate) > moment(b.soldDate))[0];
    Api.Sales.syncSale(nextSale).then(x => this.checkNeedToSyncSale);
  }

  render() {
    return <Network
      onChange={({ online }) => {
        //if (online && window.cornify_add) {
        //  window.cornify_add()
        //}
      }}
      render={({ online }) => (
        //<Badge count={this.props.unsyncedSalesCount} style={{ backgroundColor: online ? '#87d068' : '#f04134' }}>
        <div>
          <Icon type="bars" />
          {this.props.unsyncedSalesCount != 0 &&
            <span className="myBadge" style={{ backgroundColor: online ? '#87d068' : '#f04134' }}>{this.props.unsyncedSalesCount}</span>
          }
        </div>
        //</Badge>
      )} />
  }
}
const SalesMenuIcon = connect(
  (state) => ({
    sales: state.sales,
    unsyncedSalesCount: state.sales && Helpers.ObjToArray(state.sales).filter(x => x.id == null).length || 0,
  }),
  null)(_SalesMenuIcon);



// GLOBAL PROTOTYPES
String.prototype.replaceAll = function (search, replacement) {
  var target = this;
  return target.split(search).join(replacement);
};
