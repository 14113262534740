import React, { Component } from 'react';
import Button from 'antd/lib/button';
import Api from '../api/Api'

export default class Admin extends Component {
    render() {
      return (
        <iframe style={{display:'flex',flexDirection:'column',flex:1, border: 'none'}} src={Api.AdminURL}>

        </iframe>
        );
    }
}