import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Button, Icon, Modal, Input, Layout, Form, Tooltip, Select } from 'antd';
import VMasker from '../utils/VanillaMasker'
import AntdInputMask from './AntdInputMask';
import Api from '../api/Api';
import FormItem from 'antd/lib/form/FormItem';
import moment from 'moment'

export default class CustomerEdit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            customerNameOk: true,
            customerDocumentOk: true,
            customerPhoneOk: true,
            customerMailOk: true,
            customerBirthOk: true,
            customerName: props.customer && props.customer.name || '',
            customerDocument: props.customer && props.customer.document || '',
            customerMail: props.customer && props.customer.mail || '',
            customerPhone: props.customer && props.customer.phone || '',
            customerBirth: props.customer && props.customer.birth || '',
            selectedMask: '999.999.999-99',
            selectedPFPJ: 'CPF'
        }
    }

    componentWillReceiveProps(nextProps) {
        //console.log('CustomerEdit > componentWillReceiveProps > ',nextProps)
        this.setState({
            customerName: nextProps.customer && nextProps.customer.name || '',
            customerDocument: nextProps.customer && nextProps.customer.document || '',
            customerMail: nextProps.customer && nextProps.customer.mail || '',
            customerPhone: nextProps.customer && nextProps.customer.phone || '',
            customerBirth: nextProps.customer && nextProps.customer.birth || '',
        });
    }

    setCustomer = (customer, cb) => this.setState({
        customerName: customer.Name ? customer.Name : this.state.customerName,
        customerDocument: customer.Document ? customer.Document : this.state.customerDocument,
        customerMail: customer.Email ? customer.Email : this.state.customerMail,
        customerPhone: customer.Telephone ? customer.Telephone : this.state.customerPhone,
        customerBirth: customer.BirthDate ? moment(customer.BirthDate).format("DD/MM/YYYY") : this.state.customerBirth,
    }, this.changedCustomer)

    changedDocument = (doc) => {
        var that = this;
        this.setState({ loadingDoc: true })
        Api.Customers.getCustomer(doc.match(/[0-9]+/g).join('')).then(x => {
            this.setState({ loadingDoc: false })
            if (x) that.setCustomer(x);
        }).catch(e => this.setState({ loadingDoc: false }))
    }

    changedTel = (tel) => {
        var that = this;
        this.setState({ loadingTel: true })
        Api.Customers.getCustomer('', tel).then(x => {
            this.setState({ loadingTel: false })
            if (x) that.setCustomer(x);
        }).catch(e => this.setState({ loadingTel: false }))
    }

    changedCustomer = () => {
        this.props.onChangeCustomer && this.props.onChangeCustomer({
            name: this.state.customerName,
            document: this.state.customerDocument,
            mail: this.state.customerMail,
            phone: this.state.customerPhone,
            birth: this.state.customerBirth,
        })
    }

    render() {
        //console.log('RENDER CustomerEdit ===> ',this.state);
        return <div style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignSelf: 'stretch', marginBottom: 10 }}>
                <div style={{ marginRight: 10, width: '100%' }}>
                    {/* <Tooltip title={this.props.showCPFTip?"CPF na nota?":""} defaultVisible={this.props.showCPFTip}> */}
                    <FormItem validateStatus={this.state.loadingDoc ? 'validating' : ''} style={{ marginBottom: 0 }} className="formItemBlueHelpMsg"
                        help={this.props.showCPFTip ? "↖ CPF na Nota Fiscal?" : ""}
                    >
                        <AntdInputMask size="large" className="ant-input-lg" prefix={<Icon type="contacts" />} 
                            placeholder={this.state.selectedPFPJ} value={this.state.customerDocument}
                            mask={this.state.selectedMask}
                            onCompleteMask={(value) => this.changedDocument(value)}
                            readOnly={this.props.readOnly}
                            suffix={!this.state.loadingDoc && !this.props.readOnly ? <Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} /> : ''}
                            addonBefore={<Select style={{ width: 85, fontSize: 16, textAlign: 'center' }}
                                value={this.state.selectedPFPJ} onChange={(val) => { 
                                    if(val == 'CPF') this.setState({selectedPFPJ:val, selectedMask: '999.999.999-99'})
                                    else this.setState({selectedPFPJ:val, selectedMask: '99.999.999/9999-99'})
                                 }}>
                                <Select.Option value="CPF">CPF</Select.Option>
                                <Select.Option value="CNPJ">CNPJ</Select.Option>
                            </Select>}
                            onChange={(event) => this.setState({ customerDocument: event.target.value }, this.changedCustomer)} />
                    </FormItem>
                    {/* </Tooltip> */}
                </div>
                <div style={{ width: '70%' }}>
                    <Input size="large" className="ant-input-lg" prefix={<Icon type="user" />} placeholder="Nome" value={this.state.customerName}
                        readOnly={this.props.readOnly}
                        onChange={(event) => this.setState({ customerName: event.target.value }, this.changedCustomer)} />
                </div>
            </div>
            <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', alignSelf: 'stretch', marginLeft: 0 }}>
                <div style={{ marginRight: 10, width: '100%' }}>
                    <FormItem validateStatus={this.state.loadingTel ? 'validating' : ''} style={{ marginBottom: 0 }}>
                        <AntdInputMask size="large" className="ant-input-lg" prefix={<Icon type="mobile" />} placeholder="Telefone" value={this.state.customerPhone}
                            mask="(99)9999-99999"
                            onCompleteMask={(val) => this.changedTel(val)}
                            readOnly={this.props.readOnly}
                            suffix={!this.state.loadingTel && !this.props.readOnly ? <Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} /> : ''}
                            onChange={(event) => this.setState({ customerPhone: event.target.value }, this.changedCustomer)}
                        />
                    </FormItem>
                </div>
                <div style={{ marginRight: 10, width: '80%' }}>
                    <AntdInputMask size="large" className="ant-input-lg" prefix={<Icon type="calendar" />} placeholder="Aniversário" value={this.state.customerBirth}
                        mask="99/99/9999"
                        readOnly={this.props.readOnly}
                        onChange={(event) => this.setState({ customerBirth: event.target.value }, this.changedCustomer)}
                    />
                </div>
                <div style={{ width: '100%' }}>
                    <Input size="large" className="ant-input-lg" prefix={<Icon type="mail" />} placeholder="e-Mail" value={this.state.customerMail}
                        readOnly={this.props.readOnly}
                        onChange={(event) => this.setState({ customerMail: event.target.value }, this.changedCustomer)} />
                </div>
            </div>
        </div>
    }
}