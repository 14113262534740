import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Button, Icon, Modal, Input, Layout, Form } from 'antd';
import Colors from '../constants/Colors'
import Helpers from '../utils/Helpers'
import { setProductToActiveCart, getCart, removeFromCart } from '../store/cartsReducer'
import Api from '../api/Api'
import DiscountInput from './DiscountInput'
import CurrencyInput from 'react-currency-input';
import WebPOSHelper, { loadWebPOSLoader } from '../utils/webpos.helper'
var SimpleCurrencyInput = require('react-simple-currency');
var Big = require('big.js');

class ProductEdit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modalEditProductOpened: false,
            prod: { Name: 'NOVO' },
            prodNameOk: true,
            prodNameError: '',
            prodPriceOk: true,
            prodPriceError: '',
            prodQtdOk: true,
            prodQtdError: '',
            prodDiscountOk: true,
            prodDiscountError: '',
            // price
            price: 0,
            // adding new prod or updating prod on cart?
            adding: false
        }
        if (props.myRef) props.myRef(this);
    }

    componentWillReceiveProps(nextProps) {
        //if(nextProps.edit)
    }

    editProduct = (prod, addingNewProd = false) => {
        this.setState({ prod, price: Number(new Big(prod.Price).times(100)), modalEditProductOpened: true, adding: addingNewProd });
    }
    setQuantity = (q) => {
        if (isNaN(q)) return;
        this.setState({ prod: { ...(this.state.prod), Quantity: q } })
    }
    addQuantity = () => {
        this.setState({ prod: { ...(this.state.prod), Quantity: Number(new Big(this.state.prod.Quantity).plus(this.state.prod.QtyStep || 1)) } })
    }
    removeQuantity = () => {
        console.log('Math MINUS: ', this.state.prod.Quantity, '-', this.state.prod.QtyStep);
        var q = Number(new Big(this.state.prod.Quantity).minus(this.state.prod.QtyStep || 1));
        this.setState({ prod: { ...(this.state.prod), Quantity: q < 0 ? 0 : q } })
    }

    onEditProductOk = () => {
        var prod = this.state.prod;
        prod.Quantity = Number(new Big(this.state.prod.Quantity));
        this.props.dispatch(setProductToActiveCart(prod));
        this.setState({ modalEditProductOpened: false })
    }
    onEditProductCancel = () => {
        this.setState({ modalEditProductOpened: false })
    }
    onEditProductRemove = () => {
        this.props.dispatch(removeFromCart(this.state.prod, this.props.activeCartId));
        this.setState({ modalEditProductOpened: false })
    }

    setDiscount = (discount) => {
        var prod = this.state.prod;
        this.setState({ prod: { ...prod, discount } });
    }

    readFromScale = async () => {
        this.setState({ readingScaleData: true })
        const webpos = await loadWebPOSLoader();
        webpos.askScaleWeight(
            scaleData => {
                this.setState({ readingScaleData: false })
                if (!isNaN(scaleData * 1)) {
                    this.setState({ prod: { ...this.state.prod, Quantity: Number(new Big(scaleData).div(1000)) } })
                }
                console.log('scaleData: ', JSON.stringify(scaleData));
            },
            () => {
                this.setState({ readingScaleData: false })
                alert('Falha ao ler da balança!')
            }
        )
    }

    render() {
        var prod = this.state.prod;
        var rawDiscountValue = (!prod.discount || prod.discount.value == 0) ?
            0 // se nao tem desconto, deixa 0
            : prod.discount.type == 'value' ? // base do desconto eh valor fixo?
                prod.discount.value : // sim, subtrai o valor fixo
                Number(new Big(prod.Quantity || 0).times(prod.Price || 0).times(prod.discount.value || 0).times(0.01)); // se nao, valor percentual
        console.log('rawDiscountValue', rawDiscountValue, 'qty', prod.Quantity, 'price', prod.Price)
        return <Modal
            visible={this.state.modalEditProductOpened}
            title={'Editando produto no carrinho'
                //`${prod && prod.Name || 'Produto avulso'}`
            }
            onOk={this.onEditProductOk}
            onCancel={this.onEditProductCancel}
            footer={<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Button key="remove" size="large" type="danger" onClick={this.onEditProductRemove} icon="delete">Remover</Button>
                {/* <Button key="cancel" size="large" onClick={this.onEditProductCancel}>Cancelar</Button> */}
                <span style={{ marginLeft: 10, fontSize: 18, lineHeight: '37px', color: Colors.themeColor }}>
                    Total: {Helpers.FloatToReais(!prod && 0 || Number(new Big(prod.Quantity || 0).times(prod.Price || 0).minus(rawDiscountValue || 0)))}
                </span>
                <Button key="submit" size="large" type="primary" onClick={this.onEditProductOk} icon="shopping-cart">
                    {this.state.adding ? 'Inserir' : 'Atualizar'}
                </Button>
            </div>}
        >
            <Form onSubmit={this.onEditProductOk}>
                <Form.Item style={{ marginBottom: 0 }}
                    label="Nome"
                    validateStatus={!this.state.prodNameOk ? 'error' : ''}
                    help={this.state.prodNameError || ''}
                >
                    <Input value={prod.Name} onChange={(e) => this.setState({ prod: { ...prod, Name: e.target.value } })} placeholder="Nome" />
                </Form.Item>
                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
                    <div style={{ display: 'flex', flex: 2 }}>
                        <div className="codebox" style={{ width: '100%', marginRight: 20 }}>
                            <span className="codebox-title" style={{ fontSize: 14 }}>Descrição:</span>
                            <div>
                                {prod.Description}
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flex: 1 }}>
                        <div className="codebox" style={{ width: '100%' }}>
                            <span className="codebox-title" style={{ fontSize: 14 }}>Tags:</span>
                            <div>
                                {prod.Tags && prod.Tags.map(x => '#' + x).join(', ')}
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Form.Item style={{ flex: 2 }}
                        label={`Preço (${prod.QtyName || 'unid.'})`}
                        validateStatus={!this.state.prodNameOk ? 'error' : ''}
                        help={this.state.prodNameError || ''}
                    >
                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', paddingTop: 6 }}>
                            <SimpleCurrencyInput
                                className='ant-input ant-input-lg txCenter'
                                value={this.state.price}
                                precision={2}
                                separator=','
                                delimiter='.'
                                unit='R$'
                                onInputChange={(raw, val) => { console.log('VALS', raw, val); this.setState({ price: raw, prod: { ...prod, Price: Number(new Big(raw).div(100)) } }); }}
                            />
                        </div>
                    </Form.Item>
                    <div style={{ flex: 3, marginRight: 20, marginLeft: 20 }}>
                        <Form.Item style={{ flex: 1, marginBottom: 0 }}
                            label={'Quantidade' + (prod.QtyName ? ' (' + prod.QtyName + ')' : '')}
                            validateStatus={!this.state.prodNameOk ? 'error' : ''}
                            help={this.state.prodNameError || ''}
                        >
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                <Input value={prod.Quantity} placeholder="Quantidade" className="txCenter ant-input-lg"
                                    onChange={(e) => this.setQuantity(e.target.value)}
                                    addonBefore={<Button style={styles.buttonTransp} type="ghost" shape="circle"
                                        onClick={() => this.removeQuantity()}
                                        icon="minus-circle" />}
                                    addonAfter={<Button style={styles.buttonTransp} type="ghost" shape="circle"
                                        onClick={() => this.addQuantity()}
                                        icon="plus-circle" />}
                                />
                                {prod.Bulk && <Button key="submit" size="small" type="primary" icon="download" onClick={this.readFromScale}
                                    style={{ marginTop: 10 }}>
                                    {this.state.readingScaleData ? 'aguarde...' : 'Ler balança'}
                                    {prod.TrackStock && <span>estoque:{prod.QtyInStock}</span>}
                                </Button>}
                            </div>
                        </Form.Item>
                    </div>
                    <Form.Item style={{ flex: 3 }}
                        label="Desconto"
                        validateStatus={!this.state.prodDiscountOk ? 'error' : ''}
                        help={this.state.prodDiscountError || ''}
                    >
                        <DiscountInput value={prod.discount} ref={(me) => this.discountInput = me} onChange={this.setDiscount} />
                    </Form.Item>
                </div>
            </Form>
        </Modal>
    }
}

const styles = {
    buttonTransp: {
        borderWidth: 0,
        fontSize: 18
    }
}
export default connect(
    (state) => ({
        activeCartId: state.cart.activeCartId,
    }),
    null)(ProductEdit);