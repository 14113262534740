const NormalizeString = (str) => {
  var string = str;
  var mapaAcentosHex 	= {
    a : /[\xE0-\xE6]/g,
    A : /[\xC0-\xC6]/g,
    e : /[\xE8-\xEB]/g,
    E : /[\xC8-\xCB]/g,
    i : /[\xEC-\xEF]/g,
    I : /[\xCC-\xCF]/g,
    o : /[\xF2-\xF6]/g,
    O : /[\xD2-\xD6]/g,
    u : /[\xF9-\xFC]/g,
    U : /[\xD9-\xDC]/g,
    c : /\xE7/g,
    C : /\xC7/g,
    n : /\xF1/g,
    N : /\xD1/g
  };
  for ( var letra in mapaAcentosHex ) {
    var expressaoRegular = mapaAcentosHex[letra];
    string = string.replace( expressaoRegular, letra );
  }
  return string;
}
  

export default {
    FloatToReais: (price) => {
    if (!price || price == null) {
      return null
    }
    if(typeof(price) == 'string') price = Number(price);
    return (
      'R$ ' +
      price
        .toFixed(2) //TO Decimal
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.') //Set Dots
        .replace(/.([^.]*)$/, ',$1')
    ) // Replace last dot do comma
  },
  ObjToArray: (obj) => Object.keys(obj).map(x => obj[x]),
  Any: (arr,key,val) => arr.filter(x => x[key] && (!val ? true : x[key] == val)).length > 0,
  Count: (arr,fcn) => arr.filter(fcn).length,
  GroupBy: (arr, key) => {
    return arr.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  },
  NormalizeString: NormalizeString,
  ToLowerSemAcentos: (str) => NormalizeString(str.toLowerCase())
}
