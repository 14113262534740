import React, { Component } from 'react';
import Guid from 'guid'
import { connect } from 'react-redux'
import { Image, List, Header, Label } from 'semantic-ui-react'
import { Row, Col, Card, Menu, Dropdown, Button, Icon, Modal, Input, Layout, Tag, notification } from 'antd';
import Colors from '../constants/Colors'
import Helpers from '../utils/Helpers'
import { getCart, setActiveCartId, setCart, clearAllCarts, newCart, removePaymentFromCart } from '../store/cartsReducer'
import Api from '../api/Api'
import ProductEdit from './ProductEdit'
import ProductRow from './ProductRow'
import DiscountModal from './DiscountModal'
import PaymentModal from './PaymentModal'
import PaymentRow from './PaymentRow';
import CustomerModal from './CustomerModal';
import { PaymentType } from '../constants/Enums';
import WebPOSHelper, { loadWebPOSLoader } from '../utils/webpos.helper'
import CartsHeader from './CartsHeader';
const confirm = Modal.confirm;
const Content = Layout.Content;
var moment = require('moment');

class Cart extends Component {

    constructor(props) {
        super(props)
        this.state = {
            modalClearAllOpened: false,
            modalClearCartOpened: false,
            modalEditProductOpened: false,
        }
    }

    renderProducts = () => {
        var cart = this.props.activeCart;
        var prods = Helpers.ObjToArray(cart.products);
        console.log('active cart:', JSON.stringify(cart));
        if (1 > 0)
            return prods.map(p => p && <ProductRow key={p.Id} product={p} onClick={() => this.editProduct(p)} />);

        // return prods.map(p => p && <div className="clickable" style={{
        //     padding: 15, borderBottom: '1px solid #eee', minHeight: 60,
        //     flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', display: 'flex'

        // }} key={p.Id} onClick={()=>this.editProduct(p)}>
        //     <div style={{ minWidth: 40 }}>
        //         <Button shape="circle" style={styles.prodQtd}>{p.Quantity}</Button>
        //     </div>
        //     <div style={styles.prodName}>
        //         {p.Name} {p.discount ? (p.discount.type == 'percent' ? <Tag color="green">{p.discount.value}% OFF</Tag> : <Tag color="green">R$ {p.discount.value} OFF</Tag>) : ''}
        //     </div>
        //     <div style={{ minWidth: 80, textAlign: 'right' }}>
        //         <span style={styles.prodPrice}>{Helpers.FloatToReais(p.Price)}</span>
        //     </div>
        // </div>);
    }

    renderDiscount = () => {
        var cart = this.props.activeCart;
        if (cart.discount) {
            var discount = cart.discount;
            return <div className="clickable cartDiscount" style={{
                padding: 15, borderBottom: '1px solid #eee', minHeight: 60,
                flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', display: 'flex'

            }} key={'discount'} onClick={() => this.addDiscount()}>
                <div style={{ ...(styles.prodName), fontWeight: 'bold' }}>
                    Desconto
                </div>
                <div style={{ minWidth: 130, textAlign: 'right' }}>
                    <span style={styles.prodPrice}>
                        {discount ? (discount.type == 'percent' ? <Tag color="green">{discount.value}% OFF</Tag> : <Tag color="green">R$ {discount.value} OFF</Tag>) : ''}
                    </span>
                </div>
            </div>;
        }
    }

    showConfirmEraseAllCarts = () => {
        var that = this;
        return confirm({
            title: 'Tem certeza que deseja apagar TODOS os carrinhos abertos?',
            //content: 'Some descriptions',
            onOk() {
                that.props.dispatch(clearAllCarts());
            },
            onCancel() {
                console.log('Cancel');
            },
            okText: 'Sim',
            cancelText: 'Não'
        });
    }

    showConfirmEraseProducts = () => {
        var that = this;
        return confirm({
            title: 'Tem certeza que deseja apagar todos os produtos deste carrinho?',
            //content: 'Some descriptions',
            onOk() {
                if (that.props.activeCart.payments && that.props.activeCart.payments.length > 0) {
                    // there are payments on this cart, are you really sure?
                    confirm({
                        title: 'Atencão, este carrinho tem pagamentos registrados, você tem certeza que deseja apagar todos os produtos e pagamentos deste carrinho?',
                        onOk() {
                            that.props.dispatch(setCart(that.props.activeCartId, { ...(that.props.activeCart), products: {}, payments: [] }));
                            that.setState({ modalClearCartOpened: false })
                        },
                        onCancel() {
                            console.log('Cancel');
                        },
                        okText: 'Sim',
                        cancelText: 'Não'
                    });
                } else {
                    that.props.dispatch(setCart(that.props.activeCartId, { ...(that.props.activeCart), products: {}, payments: [] }));
                    that.setState({ modalClearCartOpened: false })
                }
            },
            onCancel() {
                console.log('Cancel');
            },
            okText: 'Sim',
            cancelText: 'Não'
        });
    }

    cartMenuItemclick = (item) => {
        switch (item.key) {
            case "new": this.props.dispatch(newCart())
            case "clear": this.setState({ modalClearAllOpened: true })
            default: this.props.dispatch(setActiveCartId(item.key));
        }
    }

    renderCartsMenu = () => {
        var that = this;
        var cartsArr = Helpers.ObjToArray(this.props.carts);
        return <Menu onClick={(item) => {
            if (item.key == "new") that.props.dispatch(newCart());
            else if (item.key == "clear") that.showConfirmEraseAllCarts()
            else that.props.dispatch(setActiveCartId(item.key));
        }}>
            <Menu.Item key="new" style={{ color: Colors.themeColor }}><Icon type="plus" /> Novo carrinho</Menu.Item>
            <Menu.Divider />
            {cartsArr && cartsArr.map(x => <Menu.Item key={x.guid}>
                <div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <span style={{ marginRight: 10 }}>{x.name}</span> <span>{Helpers.FloatToReais(x.sum || 0)}</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', fontSize: 11 }}>
                        <span>{x.qtd} {x.qtd == 1 ? 'produto' : 'produtos'}</span> <span>{moment(x.createdAt).format('HH:mm DD/MM')}</span>
                    </div>
                </div>
            </Menu.Item>)}
            <Menu.Divider />
            <Menu.Item key="clear" style={{ color: Colors.red }}><Icon type="delete" /> Limpar todos</Menu.Item>
        </Menu>
    }

    editProduct = (prod) => {
        this.productEdit.editProduct(prod);
    }

    addNewProduct = () => {
        var prod = { Id: Guid.raw(), Price: 0, Quantity: 1 };
        this.productEdit.editProduct(prod, true);
    }

    addDiscount = () => {
        this.discountModal.addDiscount();
    }

    addPayment = () => {
        var that = this;
        if (Helpers.Any(Helpers.ObjToArray(this.props.activeCart.products), 'SiteSale', true)) { // VENDA COM PRODUTOS DO SITE?
            return confirm({
                title: 'Fechando venda do site?',
                content: <div>Atenção, existem produtos no carrinho com o preço do site. Tem certeza que deseja concluir o pedido com estes preços? Se sim, não se esqueça de <b>adicionar o frete (embalagem)</b>.</div>,
                onOk() {
                    that.paymentModal.addPayment();
                },
                onCancel() {
                    console.log('Cancel');
                },
                okText: 'Sim',
                cancelText: 'Não'
            });
        } else { // VENDA NORMAL
            this.paymentModal.addPayment();
        }
    }

    renderPayments = () => {
        var pmts = this.props.activeCart.payments;
        return pmts.map((p, i) => (<PaymentRow key={i} payment={p} style={{ padding: 15, marginBottom: 0 }}
            onRemovePayment={() => this.removePayment(p)} editable={true} hideDetails={true} />));
    }

    removePayment = async (payment) => {
        if(payment.type == PaymentType.TEF) { // se for TEF aciona o cancelamento pelo WebPOS e dps remove
            console.log('payment:', payment);
            const webpos = await loadWebPOSLoader();
            webpos.transactions.cancel(payment.data.NumeroControle,
                (aprov,transac) => {
                    console.log('retCancelamento', JSON.stringify(aprov), JSON.stringify(transac));
                    notification['success']({
                        message: 'Transação cancelada com sucesso!',
                        description: 'O valor desta transação foi estornado no cartão! =)',
                        duration: 8
                    });
                    var imprime = window.confirm('Deseja imprimir o comprovante do cancelamento?');
                    if(imprime){
                        var tx = aprov.CupomCliente.replaceAll('"', '').replaceAll('\r', '') + '\n------------------------------------------';
                        webpos.print(tx, true, true, (ok) => { }, (err) => { });
                    }
                    this.props.dispatch(removePaymentFromCart(payment.id, this.props.activeCart.guid));
                },
                () => {
                    //console.error('Falha no pagamento!')
                    notification['error']({
                        message: 'Transação não cancelada!',
                        description: 'Ocorreu um erro ao tentar cancelar esta transação! =(',
                        duration: 8
                    });
                }
            )
        } else {
            this.props.dispatch(removePaymentFromCart(payment.id, this.props.activeCart.guid));
        }
    }

    render() {
        //console.log('carts reducer', JSON.stringify(this.props))
        return (
            <div className="noPaddingOnMobile" 
                style={{ height: this.props.screenState.screen=='mobile' ? 'calc(100vh - 128px)' : 'calc(100vh - 65px)', 
                    padding: 10, flex: 1, display: 'flex' }}>
                <ProductEdit myRef={(me) => { this.productEdit = me; }} />
                <DiscountModal myRef={(me) => { this.discountModal = me; }} discount={this.props.activeCart.discount} />
                <PaymentModal myRef={(me) => { this.paymentModal = me; }} screenState={this.props.screenState} />
                <CustomerModal myRef={(me) => { this.customerModal = me; }} customer={this.props.activeCart.customer} cartId={this.props.activeCartId} />
                <Card style={{ flex: 1 }} bodyStyle={{ padding: 0, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                    {/* CART HEADER */}
                    {/* <Row type="flex" justify="space-around" align="middle" style={{ padding: 15 }}>
                        <Col span={4}>
                            <Dropdown trigger={['click']} overlay={this.renderCartsMenu()}>
                                <Button shape="circle" icon="caret-down" size={'large'} />
                            </Dropdown>
                        </Col>
                        <Col span={16}>
                            <Input style={{ width: '100%', height: 42, textAlign: 'center', fontSize: 18 }}
                                onChange={(e, data) => {
                                    this.props.dispatch(setCart(this.props.activeCartId, { ...(this.props.activeCart), name: e.target.value }))
                                    //console.log('Name:', data.value)
                                }}
                                size={"default"} value={this.props.activeCart.name || 'Carrinho'} />
                        </Col>
                        <Col span={4} style={{ textAlign: 'right' }} width={80}>
                            <Button shape="circle" icon="delete" size={'large'} style={{ color: Colors.red }}
                                onClick={() => this.showConfirmEraseProducts()} />
                        </Col>
                    </Row> */}
                    <CartsHeader screenState={{screen:'desktop',rightOpened:true}} />

                    {/* ASSIGN CUSTOMER */}
                    <div style={styles.cartSection}>
                        <Button type="dashed" size={'large'} style={{ width: '100%' }} className="btn-huge"
                            onClick={() => this.customerModal.showModal()}>
                            <Icon type="user-add" /> {this.props.activeCart.customer ? this.props.activeCart.customer.name : 'Associar cliente'}
                        </Button>
                    </div>

                    {/* PRODUCTS */}
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', overflowY: 'auto', height: 0 }}>
                        {this.renderProducts()}
                        {this.renderDiscount()}
                    </div>

                    {/* ADD PROD / DISCOUNT */}
                    <Row style={styles.cartSection}>
                        <Col span={11}>
                            <Button style={{ width: '100%' }} type="dashed" size="large" className="btn-huge"
                                onClick={() => this.addNewProduct()}
                            ><Icon type="plus" /> Produto</Button>
                        </Col>
                        <Col span={2}>
                        </Col>
                        <Col span={11}>
                            <Button style={{ width: '100%' }} type="dashed" size="large" className="btn-huge"
                                onClick={() => this.addDiscount()}
                            ><Icon type="tag" /> Desconto</Button>
                        </Col>
                    </Row>
                    {/* TOTAL */}
                    <h3 style={{
                        padding: 15, borderBottom: '1px solid ' + Colors.themeColor, display: 'flex', margin: 0,
                        flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'
                    }}>
                        <span style={{ color: Colors.themeColor }}>Total:</span>
                        <span style={{ color: Colors.themeColor }}>{Helpers.FloatToReais(this.props.activeCartTotal)}</span>
                    </h3>

                    {/* PAYMENTS */}
                    {this.props.activeCart.payments &&
                        <div>
                            {/* <h4 style={{textAlign:'center'}}>
                          Pagamentos  
                        </h4> */}
                            {this.renderPayments()}
                        </div>
                    }

                    {/* PAY */}
                    <div style={{ padding: 15, display: 'flex' }}>
                        <Button type='primary' size="large" style={{ width: '100%' }} className="btn-huge"
                            disabled={this.props.activeCart.products == null || Object.keys(this.props.activeCart.products).length == 0}
                            onClick={() => this.addPayment()}>
                            {this.props.activeCart.amountDue > 0 ?
                                'Pagar ' + Helpers.FloatToReais(this.props.activeCart.amountDue)
                                :
                                'Concluir'
                            }
                        </Button>
                    </div>
                </Card>
            </div>
        );
    }
}

const styles = {
    cartSection: {
        justifyContent: 'space-between', display: 'flex', flexDirection: 'row', alignItems: 'center',
        backgroundColor: Colors.bgColor, padding: 15
    }
}

export default connect(
    (state) => ({
        carts: state.cart.carts,
        activeCartId: state.cart.activeCartId,
        activeCart: getCart(state.cart.carts, state.cart.activeCartId),
        activeCartTotal: getCart(state.cart.carts, state.cart.activeCartId) && getCart(state.cart.carts, state.cart.activeCartId).sum || 0
    }),
    null)(Cart);
