import React, { Component } from 'react';
import { Button, Icon, Modal, Input, Layout, Form, Menu, Radio, Card, Popconfirm } from 'antd';
import Colors from '../constants/Colors'
import Helpers from '../utils/Helpers'
import { PaymentMethod, PaymentMethodBR, PaymentType } from '../constants/Enums'

export default class PaymentRow extends Component {
    render() {
        return <div>
            <h4 style={{
                ...{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' },
                ...(!this.props.noBorder ? { borderBottom: '1px solid #e9e9e9', paddingBottom: 10, marginBottom: 10 } : {}),
                ...(this.props.style)
            }}>
                <span style={{ lineHeight: '40px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    {this.props.payment.method == PaymentMethod.MONEY ?
                        <Icon type="wallet" />
                        : this.props.payment.method == PaymentMethod.CREDIT ?
                            <Icon type="credit-card" />
                            : this.props.payment.method == PaymentMethod.DEBIT ?
                                <Icon type="credit-card" />
                                : this.props.payment.method == PaymentMethod.INSTAGRANA ?
                                    <Icon type="qrcode" />
                                    :
                                    null
                    }
                    <span style={{ marginLeft: 10, marginRight: 10 }}>
                        {PaymentMethodBR[this.props.payment.method]}
                    </span>
                    {/* BANDEIRA CARTAO */}
                    {this.props.payment.data && this.props.payment.data.NomeBandeiraCartao && <span style={{ marginLeft: 10, marginRight: 10 }}>
                        {this.props.payment.data.NomeBandeiraCartao}
                    </span>}
                    {this.props.hideDetails ? null : <span>
                        {/* NUMERO CARTAO */}
                        {this.props.payment.data && this.props.payment.data.NumeroCartaoCliente && <span style={{ marginLeft: 10, marginRight: 10 }}>
                            {this.props.payment.data.NumeroCartaoCliente}
                        </span>}
                        {/* NSU */}
                        {this.props.payment.data && this.props.payment.data.nsu && <span style={{ marginLeft: 10, marginRight: 10 }}>
                            NSU: {this.props.payment.data.nsu}
                        </span>}
                        {this.props.payment.data && this.props.payment.data.NsuTef && <span style={{ marginLeft: 10, marginRight: 10 }}>
                            NSU: {this.props.payment.data.NsuTef}
                        </span>}
                    </span>}
                    {this.props.onClick &&
                        <Button type="primary" shape="circle" icon="printer" style={{ marginLeft: 10, marginRight: 10 }} onClick={() => this.props.onClick(this.props.payment)} />
                    }
                </span>
                <span style={{ lineHeight: '40px', minWidth: 132, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>{Helpers.FloatToReais(this.props.payment.value)}
                    {this.props.editable || this.props.payment.type == PaymentType.TEF ?
                        <Popconfirm title={this.props.payment.type == PaymentType.TEF 
                                                ? "Tem certeza que deseja CANCELAR este pagamento de "+Helpers.FloatToReais(this.props.payment.value)+"?" 
                                                : "Tem certeza que deseja remover este pagamento?"}
                            onConfirm={() => this.props.onRemovePayment(this.props.payment)}
                            onCancel={() => { }} okText="Sim" cancelText="Não">
                            <Button type="dashed" size="large" shape="circle" icon="delete" style={{ marginLeft: 25 }} />
                        </Popconfirm>
                        : null
                    }
                </span>
            </h4>
        </div>
    }
}