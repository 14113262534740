const SET_PRODUCTS = 'SET_PRODUCTS'

export const setProducts = prods => ({
  type: SET_PRODUCTS,
  payload: prods
})

const initialState = {
  products: []
}

export function catalogReducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case SET_PRODUCTS:
      var newState = {...state};
      newState.products = payload;
      return newState;
    default:
      return state
  }
}

const dataSOS = [
    {
      Id: 1,
      Name: 'Cariocas',
      Order: 1,
      Products: [
        {
          Id: 153,
          Name: '#númeroHummm (300ml)',
          PhotoURL: '/Content/images/products/SobremesaGif2(1).gif',
          Description:
            'Sorvete de chocolate com pedaços de cookies de chocolate e calda de chocolate, brownie tradicional e creme de avelã',
          CategoryId: 1,
          Category: 'Cariocas',
          CategoryOrder: 0,
          Price: 39.0,
          Order: 8,
          QtyInStock: 3,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true,
          SKU: 7896423419276
        },
        {
          Id: 128,
          Name: 'Brownie do Luiz (60g)',
          PhotoURL: '/Content/images/products/brownie3.jpg',
          Description: 'Brownie Tradicional de Chocolate',
          CategoryId: 1,
          Category: 'Cariocas',
          CategoryOrder: 0,
          Price: 8.0,
          Order: 12,
          QtyInStock: 4,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        },
        {
          Id: 155,
          Name: 'Brownie do Luiz de Creme de Avelã (80g)',
          PhotoURL:
            '/Content/images/products/brownie_do_luiz_creme_de_avela_4.jpg',
          Description:
            'brownie tradicional de chocolate recheado com creme de avelã',
          CategoryId: 1,
          Category: 'Cariocas',
          CategoryOrder: 0,
          Price: 10.0,
          Order: 17,
          QtyInStock: 4,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        },
        {
          Id: 2,
          Name: 'Veneno da Lata (300g)',
          PhotoURL: '/content/images/products/veneno_da_lata.jpg',
          Description: 'Casquinhas do Tabuleiro de Brownie do Luiz',
          CategoryId: 1,
          Category: 'Cariocas',
          CategoryOrder: 0,
          Price: 36.0,
          Order: 18,
          QtyInStock: 3,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        },
        {
          Id: 5,
          Name: 'Palha da Rapha Tradicional (55g)',
          PhotoURL: '/Content/images/products/tradicional.jpg',
          Description: 'Palha Italiana de Chocolate',
          CategoryId: 1,
          Category: 'Cariocas',
          CategoryOrder: 0,
          Price: 5.0,
          Order: 19,
          QtyInStock: 7,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        },
        {
          Id: 7,
          Name: 'Palha da Rapha Oreo (55g)',
          PhotoURL: '/Content/images/products/oreo.jpg',
          Description: 'Palha Italiana de Oreo',
          CategoryId: 1,
          Category: 'Cariocas',
          CategoryOrder: 0,
          Price: 6.0,
          Order: 20,
          QtyInStock: 1,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        },
        {
          Id: 6,
          Name: 'Palha da Rapha Bombom (75g)',
          PhotoURL: '/Content/images/products/bombom.jpg',
          Description: 'Palha Italiana de Bombom',
          CategoryId: 1,
          Category: 'Cariocas',
          CategoryOrder: 0,
          Price: 8.0,
          Order: 21,
          QtyInStock: 3,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        },
        {
          Id: 123,
          Name: 'Palha da Rapha Doce de Leite (55g)',
          PhotoURL: '/Content/images/products/palhaDoceDeLeite(0).jpg',
          Description: 'Palha Italiana de Doce de Leite',
          CategoryId: 1,
          Category: 'Cariocas',
          CategoryOrder: 0,
          Price: 5.0,
          Order: 24,
          QtyInStock: 2,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        }
      ]
    },
    {
      Id: 2,
      Name: 'Sorvetes',
      Order: 2,
      Products: [
        {
          Id: 67,
          Name: 'Häagen Dazs Chocolate Midnight Cookies (473ml)',
          PhotoURL: '/Content/images/products/testemidnight.jpg',
          Description:
            'Sorvete de chocolate com pedaços de cookies de chocolate e calda de chocolate',
          CategoryId: 2,
          Category: 'Sorvetes',
          CategoryOrder: 0,
          Price: 48.0,
          Order: 1,
          QtyInStock: 3,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        },
        {
          Id: 72,
          Name: 'Häagen Dazs Belgian Chocolate (473ml)',
          PhotoURL:
            '/Content/images/products/belgian_chocolate_ice_cream_pint.jpg',
          Description:
            'Sorvete de chocolate com pedaços de chocolate meio amargo',
          CategoryId: 2,
          Category: 'Sorvetes',
          CategoryOrder: 0,
          Price: 48.0,
          Order: 2,
          QtyInStock: 5,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        },
        {
          Id: 69,
          Name: 'Häagen Dazs Cookies \u0026 Cream (473ml)',
          PhotoURL:
            '/Content/images/products/cookies_vanilla_cream_ice_cream_pint.jpg',
          Description: 'Sorvete de baunilha com pedaços de cookies de chocolate',
          CategoryId: 2,
          Category: 'Sorvetes',
          CategoryOrder: 0,
          Price: 48.0,
          Order: 3,
          QtyInStock: 3,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        },
        {
          Id: 71,
          Name: 'Häagen Dazs Dulce de Leche (473ml)',
          PhotoURL: '/Content/images/products/dulce_de_leche_ice_cream_pint.jpg',
          Description: 'Sorvete de doce de leite',
          CategoryId: 2,
          Category: 'Sorvetes',
          CategoryOrder: 0,
          Price: 48.0,
          Order: 4,
          QtyInStock: 6,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        },
        {
          Id: 4,
          Name: 'Häagen Dazs Macadamia Nut Brittle (473ml)',
          PhotoURL:
            '/Content/images/products/macadamia_nut_brittle_ice_cream_pint.jpg',
          Description: 'Sorvete de baunilha com macadâmia crocante',
          CategoryId: 2,
          Category: 'Sorvetes',
          CategoryOrder: 0,
          Price: 48.0,
          Order: 5,
          QtyInStock: 5,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        },
        {
          Id: 68,
          Name: 'Häagen Dazs Cookies \u0026 Cream (100ml)',
          PhotoURL:
            '/Content/images/products/cookies_cream_ice_cream_mini_cup.jpg',
          Description: 'Sorvete de baunilha com pedaços de cookies de chocolate',
          CategoryId: 2,
          Category: 'Sorvetes',
          CategoryOrder: 0,
          Price: 16.0,
          Order: 7,
          QtyInStock: 8,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        },
        {
          Id: 73,
          Name: 'Häagen Dazs Macadamia Nut Brittle (100ml)',
          PhotoURL: '/Content/images/products/ice_cream_mini_cup.jpg',
          Description: 'Sorvete de baunilha com macadâmia crocante',
          CategoryId: 2,
          Category: 'Sorvetes',
          CategoryOrder: 0,
          Price: 16.0,
          Order: 9,
          QtyInStock: 22,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        },
        {
          Id: 146,
          Name: 'Ben \u0026 Jerry\u0027s Choc Chip Cookie Dough (458ml)',
          PhotoURL:
            '/Content/images/products/Ben-\u0026-Jerrys-Choc-Chip-Cookie-Dough-458-ml-FINAL-2(0).jpg',
          Description:
            'Sorvete de creme com massa de biscoito e flocos sabor chocolate',
          CategoryId: 2,
          Category: 'Sorvetes',
          CategoryOrder: 0,
          Price: 42.0,
          Order: 10,
          QtyInStock: 5,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        },
        {
          Id: 115,
          Name: 'Ben \u0026 Jerry\u0027s Chocolate Fudge Brownie (458ml)',
          PhotoURL: '/Content/images/products/Ben-and-Jerrys-Fudge-BRownie.jpg',
          Description: 'Sorvete de chocolate com pedaços de bolo de chocolate',
          CategoryId: 2,
          Category: 'Sorvetes',
          CategoryOrder: 0,
          Price: 42.0,
          Order: 11,
          QtyInStock: 6,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        },
        {
          Id: 131,
          Name: 'BEN \u0026 JERRY\u0027S TRIPLE CARAMEL CHUNK (458ML)',
          PhotoURL:
            '/Content/images/products/Ben-and-Jerrys-Triple-Caramel(1).jpg',
          Description:
            'Sorvete de caramelo,  com calda de caramelo  e flocos de chocolate  recheados com  caramelo',
          CategoryId: 2,
          Category: 'Sorvetes',
          CategoryOrder: 0,
          Price: 42.0,
          Order: 12,
          QtyInStock: 5,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        },
        {
          Id: 117,
          Name: 'Ben \u0026 Jerry\u0027s Phish Food (458ml)',
          PhotoURL: '/Content/images/products/Ben-and-Jerrys-Phish-Food.jpg',
          Description:
            'Sorvete de chocolate com marshmallow, calda de caramelo e flocos em formato de peixe sabor chocolate',
          CategoryId: 2,
          Category: 'Sorvetes',
          CategoryOrder: 0,
          Price: 42.0,
          Order: 13,
          QtyInStock: 10,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        },
        {
          Id: 148,
          Name: 'Ben \u0026 Jerry\u0027s Half Baked (458ml)',
          PhotoURL: '/Content/images/products/half-baked-ate-que-enfim.jpg',
          Description:
            'sorvetes sabor chocolate e baunilha com pedaços de biscoito e bolo sabor chocolate',
          CategoryId: 2,
          Category: 'Sorvetes',
          CategoryOrder: 0,
          Price: 42.0,
          Order: 14,
          QtyInStock: 6,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        },
        {
          Id: 149,
          Name: 'Ben \u0026 Jerry\u0027s Everything but the... (458ml)',
          PhotoURL:
            '/Content/images/products/Ben-and-Jerrys-everythingbutthe.jpg',
          Description:
            'Sorvete sabor chocolate e baunilha com manteiga de amendoim, caramelo sabor chocolate, flocos sabor chocolate branco e amêndoas com cobertura sabor chocolate',
          CategoryId: 2,
          Category: 'Sorvetes',
          CategoryOrder: 0,
          Price: 42.0,
          Order: 15,
          QtyInStock: 5,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        },
        {
          Id: 118,
          Name: 'Ben \u0026 Jerry\u0027s Chocolate Chip Cookie Dough (120ml)',
          PhotoURL:
            '/Content/images/products/Ben-and-Jerrys-Cookie-Dough-PEQUENO.jpg',
          Description:
            'SORVETE DE CREME COM MASSA DE BISCOITO E FLOCOS DE CHOCOLATE',
          CategoryId: 2,
          Category: 'Sorvetes',
          CategoryOrder: 0,
          Price: 15.0,
          Order: 19,
          QtyInStock: 13,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        },
        {
          Id: 119,
          Name: 'Ben \u0026 Jerry\u0027s Chocolate Fudge Brownie (120ml)',
          PhotoURL:
            '/Content/images/products/Ben-and-Jerrys-Fudge-Brownie-PEQUENO.jpg',
          Description: 'Sorvete de chocolate com pedaços de bolo de chocolate',
          CategoryId: 2,
          Category: 'Sorvetes',
          CategoryOrder: 0,
          Price: 15.0,
          Order: 20,
          QtyInStock: 12,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        },
        {
          Id: 150,
          Name: 'Ben \u0026 Jerry\u0027s Cookie Carnival (120ml)',
          PhotoURL:
            '/Content/images/products/Ben-and-Jerrys-carnival-PEQUENO.jpg',
          Description:
            'sorvete sabor baunilha com pedaços de biscoito sabor sabor baunilha e chocolate',
          CategoryId: 2,
          Category: 'Sorvetes',
          CategoryOrder: 0,
          Price: 15.0,
          Order: 21,
          QtyInStock: 9,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        }
      ]
    },
    {
      Id: 3,
      Name: 'Importados',
      Order: 3,
      Products: [
        {
          Id: 10,
          Name: 'Ferrero Rocher (12 unidades)',
          PhotoURL: '/Content/images/products/ferrero12.jpg',
          Description:
            'BOMBOM COBERTO COM CHOCOLATE AO LEITE E PEDAÇOS DE AVELÃS COM RECHEIO CREMOSO E AVELÃ INTEIRA',
          CategoryId: 3,
          Category: 'Importados',
          CategoryOrder: 0,
          Price: 39.0,
          Order: 1,
          QtyInStock: 8,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        },
        {
          Id: 21,
          Name: 'Loacker Milk Cremkakao (87g)',
          PhotoURL: '/content/images/products/Loacker.jpg',
          Description: 'Chocolate ao leite com wafer',
          CategoryId: 3,
          Category: 'Importados',
          CategoryOrder: 0,
          Price: 20.0,
          Order: 3,
          QtyInStock: 0,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        },
        {
          Id: 159,
          Name: 'Lindt Lindor Milk Heart (48g)',
          PhotoURL: '/Content/images/products/Choc Lindt haert 48g.jpg',
          Description: 'latinha com 4 lindor balls ao leite',
          CategoryId: 3,
          Category: 'Importados',
          CategoryOrder: 0,
          Price: 30.0,
          Order: 9,
          QtyInStock: 0,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        },
        {
          Id: 139,
          Name: 'Lindt Swiss Classic Milk (100g)',
          PhotoURL:
            '/Content/images/products/Lindt Swiss Classic Swiss Milk Chocolate (100g).png',
          Description: 'Chocolate ao leite suíço',
          CategoryId: 3,
          Category: 'Importados',
          CategoryOrder: 0,
          Price: 27.0,
          Order: 10,
          QtyInStock: 0,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        },
        {
          Id: 87,
          Name: 'Lindt Swiss Gold Bar Milk (300g)',
          PhotoURL: '/Content/images/products/lindtgoldbar.jpg',
          Description: 'Chocolate ao leite',
          CategoryId: 3,
          Category: 'Importados',
          CategoryOrder: 0,
          Price: 75.0,
          Order: 14,
          QtyInStock: 0,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        },
        {
          Id: 129,
          Name: 'Milka Oreo (100g)',
          PhotoURL: '/Content/images/products/Milka Oreo(1).jpg',
          Description: 'Chocolate ao leite com pedaços de Oreo',
          CategoryId: 3,
          Category: 'Importados',
          CategoryOrder: 0,
          Price: 22.0,
          Order: 16,
          QtyInStock: 13,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        },
        {
          Id: 14,
          Name: 'Toblerone (100g)',
          PhotoURL: '/content/images/products/toblerone100g.jpg',
          Description: 'chocolate suíço ao leite com recheio de amêndoas e mel',
          CategoryId: 3,
          Category: 'Importados',
          CategoryOrder: 0,
          Price: 15.0,
          Order: 19,
          QtyInStock: 22,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        },
        {
          Id: 16,
          Name: 'Pão de Mel Schokoladen Lebkuchen (500g)',
          PhotoURL: '/content/images/products/Schokoladen.jpg',
          Description: 'pão de mel alemão da Lambertz',
          CategoryId: 3,
          Category: 'Importados',
          CategoryOrder: 0,
          Price: 62.0,
          Order: 20,
          QtyInStock: 5,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        },
        {
          Id: 18,
          Name: 'Solito de Chocolate - Alfajor Havanna (55g)',
          PhotoURL: '/content/images/products/alfajor.jpg',
          Description:
            'Alfajor com recheio de doce de leite e cobertura de chocolate ao leite',
          CategoryId: 3,
          Category: 'Importados',
          CategoryOrder: 0,
          Price: 19.0,
          Order: 21,
          QtyInStock: 5,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        },
        {
          Id: 122,
          Name: 'Língua de Gato Sarotti (100g)',
          PhotoURL:
            '/Content/images/products/Língua de Gato Katzenzungen 100g Site.jpg',
          Description: 'Chocolate ao leite em forma de língua de gato',
          CategoryId: 3,
          Category: 'Importados',
          CategoryOrder: 0,
          Price: 49.0,
          Order: 23,
          QtyInStock: 4,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        },
        {
          Id: 156,
          Name: 'Bombom Baci Perugina (42,9g)',
          PhotoURL: '/Content/images/products/baci perugina.png',
          Description: '3 bombons de chocolate ao leite com recheio de avelãs',
          CategoryId: 3,
          Category: 'Importados',
          CategoryOrder: 0,
          Price: 20.0,
          Order: 30,
          QtyInStock: 0,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        }
      ]
    },
    {
      Id: 4,
      Name: 'Funcional',
      Order: 5,
      Products: [
        {
          Id: 17,
          Name: 'Essential Chocolift (40g)',
          PhotoURL:
            '/Content/images/products/6943494_chocolift-essential-nutrition-5694_l1_636217316833156000.png',
          Description:
            'Chocolate com Whey Protein Isolado, Óleo de Coco e Fibras. Zero açúcar, lactose e gordura trans',
          CategoryId: 4,
          Category: 'Funcional',
          CategoryOrder: 0,
          Price: 24.0,
          Order: 10,
          QtyInStock: 4,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        },
        {
          Id: 141,
          Name: 'AMMA 75% Cacau - Nibirus (80g)',
          PhotoURL: '/Content/images/products/AMMAnibirus.jpg',
          Description: 'chocolate orgânico com 75% cacau',
          CategoryId: 4,
          Category: 'Funcional',
          CategoryOrder: 0,
          Price: 30.0,
          Order: 12,
          QtyInStock: 5,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        },
        {
          Id: 42,
          Name: 'Talento Avelãs Diet (25g)',
          PhotoURL: '/content/images/products/talentoAvelaDiet.jpg',
          Description: 'chocolate ao leite com avelãs e sem adição de açúcar',
          CategoryId: 4,
          Category: 'Funcional',
          CategoryOrder: 0,
          Price: 5.5,
          Order: 13,
          QtyInStock: 12,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        }
      ]
    },
    {
      Id: 7,
      Name: 'Clássicos',
      Order: 6,
      Products: [
        {
          Id: 28,
          Name: 'Diamante Negro (135g)',
          PhotoURL: '/content/images/products/diamanteNegro.jpg',
          Description: 'Chocolate ao Leite com caju e mel',
          CategoryId: 7,
          Category: 'Clássicos',
          CategoryOrder: 0,
          Price: 11.5,
          Order: 1,
          QtyInStock: 10,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        },
        {
          Id: 29,
          Name: 'Alpino (125g)',
          PhotoURL: '/content/images/products/alpino.jpg',
          Description: 'Chocolate ao leite cremoso',
          CategoryId: 7,
          Category: 'Clássicos',
          CategoryOrder: 0,
          Price: 11.5,
          Order: 2,
          QtyInStock: 8,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        },
        {
          Id: 101,
          Name: 'Laka Oreo (150g)',
          PhotoURL: '/Content/images/products/laka oreo.jpg',
          Description: 'chocolate branco com pedaços de oreo',
          CategoryId: 7,
          Category: 'Clássicos',
          CategoryOrder: 0,
          Price: 10.0,
          Order: 4,
          QtyInStock: 8,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        },
        {
          Id: 30,
          Name: 'M\u0026M Chocolate (200g)',
          PhotoURL: '/content/images/products/MeMChocolate.jpg',
          Description: 'M\u0026M Chocolate',
          CategoryId: 7,
          Category: 'Clássicos',
          CategoryOrder: 0,
          Price: 14.0,
          Order: 5,
          QtyInStock: 5,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        },
        {
          Id: 31,
          Name: 'M\u0026M Amendoim (200g)',
          PhotoURL: '/content/images/products/MeMAmendoim.jpg',
          Description: 'M\u0026M Amendoim',
          CategoryId: 7,
          Category: 'Clássicos',
          CategoryOrder: 0,
          Price: 14.0,
          Order: 6,
          QtyInStock: 15,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        },
        {
          Id: 26,
          Name: 'Hersheys Chocolate ao Leite (110g)',
          PhotoURL: '/content/images/products/HersheysChocolate.jpg',
          Description: 'Chocolate ao Leite',
          CategoryId: 7,
          Category: 'Clássicos',
          CategoryOrder: 0,
          Price: 10.0,
          Order: 7,
          QtyInStock: 12,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        },
        {
          Id: 27,
          Name: 'Hersheys Cookies n Creme (110g)',
          PhotoURL: '/Content/images/products/hersheys cookies and creme.jpg',
          Description: 'Chocolate branco com pedaços de cookies crocantes',
          CategoryId: 7,
          Category: 'Clássicos',
          CategoryOrder: 0,
          Price: 10.0,
          Order: 8,
          QtyInStock: 10,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        },
        {
          Id: 94,
          Name: 'Hersheys Meio Amargo (115g)',
          PhotoURL: '/Content/images/products/hersheys meio amargo.JPG',
          Description: 'Chocolate meio amargo (40% cacau)',
          CategoryId: 7,
          Category: 'Clássicos',
          CategoryOrder: 0,
          Price: 10.0,
          Order: 10,
          QtyInStock: 8,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        },
        {
          Id: 13,
          Name: 'Nutella (350g)',
          PhotoURL: '/Content/images/products/nutellagrande.jpg',
          Description: 'Creme de Avelã com Cacau',
          CategoryId: 7,
          Category: 'Clássicos',
          CategoryOrder: 0,
          Price: 30.0,
          Order: 12,
          QtyInStock: 5,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        },
        {
          Id: 57,
          Name: 'Ovomaltine (380g)',
          PhotoURL: '/Content/images/products/ovomaltineCreme.jpg',
          Description: 'creme crocante de avelã e cacau a base de Ovomaltine',
          CategoryId: 7,
          Category: 'Clássicos',
          CategoryOrder: 0,
          Price: 35.0,
          Order: 13,
          QtyInStock: 12,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        },
        {
          Id: 45,
          Name: 'Kit Kat (45g)',
          PhotoURL: '/content/images/products/kitkat.jpg',
          Description: 'Wafer coberto com chocolate',
          CategoryId: 7,
          Category: 'Clássicos',
          CategoryOrder: 0,
          Price: 5.5,
          Order: 14,
          QtyInStock: 31,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        },
        {
          Id: 32,
          Name: 'Snickers (52,7g)',
          PhotoURL: '/content/images/products/snickers.jpg',
          Description:
            'barra de chocolate em torrone (nougat) de manteiga de amendoim coberto com amendoins e caramelo com chocolate ao leite',
          CategoryId: 7,
          Category: 'Clássicos',
          CategoryOrder: 0,
          Price: 5.0,
          Order: 15,
          QtyInStock: 10,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        },
        {
          Id: 130,
          Name: 'Kinder Bueno (43g)',
          PhotoURL: '/Content/images/products/Kinder-Bueno-2(0).jpg',
          Description: 'Chocolate com recheio de leite e avelãs',
          CategoryId: 7,
          Category: 'Clássicos',
          CategoryOrder: 0,
          Price: 9.0,
          Order: 16,
          QtyInStock: 12,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        },
        {
          Id: 34,
          Name: 'Bis Xtra (45g)',
          PhotoURL: '/Content/images/products/bis-xtra.jpg',
          Description: 'Chocolate Bis Xtra recheado com Wafer',
          CategoryId: 7,
          Category: 'Clássicos',
          CategoryOrder: 0,
          Price: 5.5,
          Order: 18,
          QtyInStock: 30,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        },
        {
          Id: 36,
          Name: 'Twix (45g)',
          PhotoURL: '/content/images/products/twix45g.jpg',
          Description: 'Chocolate recheado com biscoito e caramelo',
          CategoryId: 7,
          Category: 'Clássicos',
          CategoryOrder: 0,
          Price: 5.0,
          Order: 19,
          QtyInStock: 6,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        },
        {
          Id: 151,
          Name: 'Caixa de Bombom Lacta Grandes Sucessos (302g)',
          PhotoURL: '/Content/images/products/caixa de bombom lacta.jpg',
          Description:
            'Lacta Sonho de Valsa, Sonho de Valsa Mais, Lacta Ouro Branco, Lacta Laka, Lacta - Chocolate ao Leite, Lacta Diamante Negro, Lacta Bis, LACTA AMANDITA, Amandita Due e Sonho de Valsa ChocoMais',
          CategoryId: 7,
          Category: 'Clássicos',
          CategoryOrder: 0,
          Price: 17.0,
          Order: 25,
          QtyInStock: 10,
          Quantity: 0,
          UploadedImage: null,
          IsSelling: true
        }
      ]
    }
  ]
  
  // const initialState = {
  //   categories: dataSOS
  // }
  
  // export function catalogReducer(state = initialState, action) {
  //   const { type, payload } = action
  //   // const { cartProductsById } = state
  
  //   switch (type) {
  //     // case ADD_TO_CART:
  //     //   const newProds = {
  //     //     ...cartProductsById
  //     //   }
  //     //   const found = cartProductsById[payload.Id]
  //     //   // adicionando novo produto
  //     //   if (!found) {
  //     //     newProds[payload.Id] = {
  //     //       ...payload,
  //     //       quantity: 1
  //     //     }
  //     //   } else {
  //     //     // ja tinha esse prod no carrinho
  //     //     newProds[payload.Id] = {
  //     //       ...found,
  //     //       quantity: found.quantity + 1
  //     //     }
  //     //   }
  //     //   return packState(newProds)
  
  //     // case REMOVE_FROM_CART:
  //     //   return packState(
  //     //     Object.keys(state.cartProductsById).reduce((prodsById, id) => {
  //     //       if (id !== payload.Id) {
  //     //         prodsById[id] = state.cartProductsById[id]
  //     //         return prodsById
  //     //       }
  
  //     //       const qty = state.cartProductsById[id].quantity
  //     //       if (qty > 1) {
  //     //         prodsById[id] = {
  //     //           ...state.cartProductsById[id],
  //     //           quantity: qty - 1
  //     //         }
  //     //         return prodsById
  //     //       }
  
  //     //       return prodsById
  //     //     }, {})
  //     //   )
  
  //     // case CLEAN_CART:
  //     //   return initialState
  
  //     // case CLOSE_CART:
  //     //   return {
  //     //     ...state,
  //     //     isClosed: payload
  //     //   }
  
  //     default:
  //       return state
  //   }
  // }
  