import React from 'react';
import { Input } from 'antd';
import VMasker from '../utils/VanillaMasker'

export default class AntdInputMask extends React.PureComponent {

    constructor(props){
        super(props);
        this.state = {
            value:props.value
        }
    }

    componentDidMount(){
        var maskedVal = VMasker.toPattern(this.props.value, this.props.mask);
        this.setState({value:maskedVal});
    }

    innerOnChange = (event) => {
        var maskedVal = VMasker.toPattern(event.target.value, this.props.mask);
        event.target.value = maskedVal;
        if (maskedVal.length == this.props.mask.length && this.props.onCompleteMask) {
            this.props.onChange(event);
            setTimeout(() => this.props.onCompleteMask(maskedVal),1)
        } else {
            this.props.onChange(event);
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.value != this.state.value){
            var maskedVal = VMasker.toPattern(nextProps.value, nextProps.mask);
            this.setState({value:maskedVal})
        }
    }

    render() {
        return <Input {...this.props} value={this.state.value}
            onChange={(event) => this.innerOnChange(event)}
        />
    }
}