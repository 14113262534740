import React, { Component } from 'react';
import { connect } from 'react-redux'
import Product from '../components/Product'
import Api from '../api/Api'
import Cart from '../components/Cart'
import Colors from '../constants/Colors'
import Helpers from '../utils/Helpers'
import GlobalStyles from '../constants/GlobalStyles'
import { addToCart, setActiveCart } from '../store/cartsReducer'
import { Transition, animated } from 'react-spring'
import { Layout, Menu, Icon, Tabs, Button, Input, Select } from 'antd';
import ResponsiveScreen from './../components/ResponsiveScreen';
import MediaQuery from 'react-responsive';
import CartsHeader from '../components/CartsHeader';
const TabPane = Tabs.TabPane;
const { Header, Content, Footer, Sider } = Layout;
const Option = Select.Option;

class PDV extends Component {
  constructor(props) {
    super(props)
    var categories = null;
    if (props.catalog && props.catalog.products)
      categories = this.prepareCatalog(props.catalog.products);
    this.state = {
      categoriesShown: false,
      categories: categories,
      selectedCategory: categories && categories[0] || null,
      searchText: '',
      showOnlinePrice: false,
      showingProds: 40
    }
    this.handleKeyPressGlobal = this.handleKeyPressGlobal.bind(this);
  }

  componentDidMount() {
    console.log('fetch products from PDV.js')
    Api.Products.getProducts();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.catalog && nextProps.catalog.products)
      this.setState({ categories: this.prepareCatalog(nextProps.catalog.products) },
        () => {
          //if (!this.state.selectedCategory)
          this.setState({ selectedCategory: this.state.categories[0] });
        })
  }

  prepareCatalog = (products) => {
    var cats = [
      {
        Id: 0, Name: 'Todos', Products: products
        // Products: categories.reduce((prev, curr, idx) => {
        //   return prev.concat(curr.Products);
        // }, [])
      }
    ];

    //divide categories:
    if (products && Helpers.Any(products, 'Category')) {
      var generatedCategories = Helpers.GroupBy(products, 'Category');
      var auxArr = Helpers.ObjToArray(generatedCategories);
      var auxCats = auxArr.map(x => ({ Id: x[0].CategoryId, Name: x[0].Category, Products: x.sort((a, b) => a.Order < b.Order) }));
      cats = cats.concat(auxCats.sort((a, b) => a.CategoryOrder < b.CategoryOrder));
    }

    //divide tags:
    if (products && Helpers.Any(products, 'Tags')) {
      var objTags = {};
      products.map(x => x.Tags && x.Tags.map(t => { t = '#' + t; objTags[t] = objTags[t] || []; objTags[t].push(x) }))
      var auxArr = Object.keys(objTags).map(x => ({ Id: x, Name: x, Products: objTags[x].sort((a, b) => a.Order < b.Order) }))
      cats = cats.concat(auxArr.sort((a, b) => {
        var aName = a.Name.replace('#', '').toLowerCase();
        var bName = b.Name.replace('#', '').toLowerCase();
        return aName < bName ? -1 : aName > bName ? 1 : 0;
      }));
    }



    console.log('RETURNING CATEROGIRESSS =>>> ', cats)
    return cats;
  }

  // getTabs = () => {
  //   var tabStyle = { backgroundColor: Colors.bgColor, padding: 0, border: 'none', boxShadow: 'none' };
  //   var allProds = [];
  //   this.props.catalog && this.props.catalog.products.map(c => {
  //     return c.Products.map(x => allProds.push(x));
  //   });
  //   console.log('allProds!', allProds.length)
  //   var tabs = [
  //     { menuItem: 'Todos', render: () => <Tab.Pane key={0} style={tabStyle} attached={false}>{this.renderProds(allProds)}</Tab.Pane> },
  //   ];
  //   this.props.catalog && this.props.catalog.products.map(c => {
  //     tabs.push({ menuItem: c.Name, render: () => <Tab.Pane key={c.Id} style={tabStyle} attached={false}>{this.renderProds(c.Products)}</Tab.Pane> })
  //   });
  //   tabs.push({ menuItem: 'Busca', render: () => <Tab.Pane key={999} style={tabStyle} attached={false}></Tab.Pane> })
  //   return tabs;
  // }

  filteredProducts = (prods) => {
    prods = prods.filter(p => this.state.showOnlinePrice ? p.SellingOnline : p.SellingOnStore);
    if (this.state.searchText) {
      var tx = Helpers.NormalizeString(this.state.searchText.toLowerCase());
      return prods.filter(p => {
        return Helpers.NormalizeString(p.Name.toLowerCase()).indexOf(tx) > -1 // name search
          || // price search
          (p.Price.toFixed(2).toString().indexOf(tx.replace(',', '.')) > -1)
          ||  // sku search
          (p.SKU && p.SKU.toString().toLowerCase().indexOf(tx) > -1)
          ||  // tags search
          (p.Tags && p.Tags.filter(x => Helpers.NormalizeString('#' + x.toLowerCase()).indexOf(tx) > -1).length > 0)
      })
    }
    return prods;
  }

  renderProds = (selectedCategory) => {
    var prods = selectedCategory.Products;
    // filter searchText:
    prods = this.filteredProducts(prods);
    // styles
    var defaultStyles = {
      display: 'flex', flex: 1, flexDirection: 'row', flexWrap: 'wrap',
      justifyContent: 'flex-start', alignItems: 'flex-start', alignContent: 'flex-start', placeContent: 'center'
    };

    var totallen = prods.length;
    prods = prods.filter((x, i) => i <= this.state.showingProds);
    var len = prods.length;

    prods = prods.map(x => { return { ...x, Price: this.state.showOnlinePrice ? x.PriceOnline : x.Price, SiteSale: this.state.showOnlinePrice } });

    return (
      <div style={defaultStyles}>
        {prods.map(p => <Product Product={p} onClick={() => this.props.dispatch(addToCart(p, this.props.activeCartId))} />)}
        {len < totallen ?
          <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', height: 70, alignItems: 'center' }}>
            <Button onClick={() => this.setState({ showingProds: this.state.showingProds + 40 })}>
              Mostrar mais produtos
            </Button>
          </div>
          : null}
      </div>);
  }

  renderCategoriesList = () => {
    if (!this.props.catalog) return;
    return this.state.categories.map(c => (
      <div key={c.Id} className={`clickable ${this.state.selectedCategory && c.Id == this.state.selectedCategory.Id ? 'itemSelected' : ''}`} style={{
        padding: 15, borderBottom: '1px solid #eee', minHeight: 60, alignSelf: 'stretch',
        flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', display: 'flex'
      }}
        onClick={() => { this.setState({ selectedCategory: c }) }}>
        <span>{c.Name}</span>
      </div>));
  }

  searchKeyPress = (event) => {
    if (event.key == 'Enter') { // provavelmente é um leitor de barcode dando Enter apos digitar o codigo do produto
      var filteredProds = this.filteredProducts(this.state.categories[0].Products); // filtra na categoria = TODOS
      var selectedProd = filteredProds && filteredProds.length > 0 && filteredProds[0];
      if (selectedProd)
        this.props.dispatch(addToCart(selectedProd, this.props.activeCartId))
      this.setState({ searchText: '' })
    }
  }

  // handle global keypresses for barcoder readers
  handleKeyPressGlobal = function (event) {
    //console.log('EVENTO:',event);
    // se nao tiver digitando num input ou select, intercepto e mando pro input de busca
    if (event.target.tagName != 'INPUT' && event.target.tagName != 'SELECT') {
      // var evt = new event.constructor(event.type, event);
      // evt.initEvent("keydown", true, true);
      // document.getElementById('productSearchInput').dispatchEvent(evt);
      try {
        document.getElementById('productSearchInput').focus();
      } catch (e) { }
      // this.setState({searchText: event.key}, () => {
      // })
    }
  }

  componentWillMount() {
    //document.addEventListener("keyPress", this.handleKeyPressGlobal, false);
    window.onkeydown = this.handleKeyPressGlobal;
  }

  componentWillUnmount() {
    //document.removeEventListener("keyPress", this.handleKeyPressGlobal, false);
    window.onkeydown = null;
  }

  render() {
    // let mql = window.matchMedia('(max-width: 600px)');
    return <Layout style={{ flexDirection: 'row' }}>
      <ResponsiveScreen style={{ flex: 1 }}
        left={(screenState) => (
          <Content style={{ flex: 7, flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
            <Content style={{ flex: 1, maxWidth: '100%', overflow: 'auto', display: 'flex', flexDirection: 'row' }}>
              {/* {this.renderCatalog()} */}

              {/* CATEGORIES LIST SIDEBAR */}
              <div width={'auto'} style={{
                ...(GlobalStyles.leftCol), flex: 1, transition: 'all 0.2s', transitionTimingFunction: 'ease-out',
                maxWidth: this.state.categoriesShown ? 200 : 0,
                minWidth: this.state.categoriesShown ? 200 : 0,
                maxHeight: 'calc(100vh - 65px)', overflowY: 'auto', overflowX: 'hidden'
              }}>
                {/* SEARCH INPUT */}
                {/* <div style={{
              padding: 15, borderBottom: '1px solid #eee', minHeight: 60, alignSelf: 'stretch',
              flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', display: 'flex'
            }}>
              <Input placeholder="Busca" className="ant-input ant-input-lg txCenter"
                value={this.state.searchText} id="productSearchInput"
                onChange={(e) => { this.setState({ searchText: e.target.value }) }}
                onKeyPress={this.searchKeyPress} />
            </div> */}
                {/* LIST OF CATEGORIES */}
                {this.props.catalog && this.renderCategoriesList()}
              </div>

              {/* MAIN BOARD */}
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', maxWidth: '100%', width: '100%', flex: 1 }}>
                {/* SEARCH INPUT */}
                <div style={{
                  padding: 8, minHeight: 60, alignSelf: 'stretch',
                  flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', display: 'flex', maxWidth: '100%', width: '100%',
                }}>
                  <Button shape="circle" style={{ marginRight: 8, minHeight: 40, minWidth: 40 }}
                    onClick={() => { this.setState({ categoriesShown: !this.state.categoriesShown }); window.categoriesShown = !this.state.categoriesShown }}>
                    {this.state.categoriesShown ?
                      <Icon type="menu-fold" theme="outlined" />
                      : <Icon type="menu-unfold" theme="outlined" />}
                  </Button>
                  <Input placeholder="Busque produtos por nome, hashtag ou código de barras" className="ant-input ant-input-lg txCenter"
                    value={this.state.searchText} id="productSearchInput" style={{ border: 'none' }}
                    onChange={(e) => { this.setState({ searchText: e.target.value, showingProds: 40 }) }}
                    onKeyPress={this.searchKeyPress}
                    prefix={<Icon type="search" style={{ fontSize: 16 }} />}
                    suffix={this.state.searchText ?
                      <Icon style={{ padding: 10, fontSize: 16 }}
                        onClick={() => this.setState({ searchText: '' })} type="close-circle" theme="outlined" />
                      : null}
                  />
                  <Select defaultValue="loja" style={{ width: 140, marginLeft: 8 }} size="large"
                    onChange={(v) => this.setState({ showOnlinePrice: v == 'site' })}>
                    <Option className="selectNoCheckSign" value="loja">Preços Loja</Option>
                    <Option className="selectNoCheckSign" value="site">Preços Site</Option>
                  </Select>
                </div>

                {/* PRODUCTS */}
                <div className="hoverScroll" style={{
                  flex: 1, maxWidth: '100%', width: '100%', overflow: 'auto',
                  display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', padding: 5, paddingBottom: 0,
                  maxHeight: 'calc(100vh - 130px)'
                }}>
                  {this.props.catalog && this.state.selectedCategory ?
                    this.renderProds(this.state.selectedCategory) :
                    'Carregando produtos...'
                  }
                </div>
              </div>
            </Content>
          </Content>
        )}
        leftStyle={{ flex: 7 }}
        bottomSheet={(screenState) => <CartsHeader screenState={screenState} />}
        right={(screenState) => (
          <Content className="widthAutoOnMobile" width={'auto'} style={{ flex: 3, maxWidth: screenState.screen == 'mobile' ? 'auto' : '100%', minWidth: 280 }}>
            <Cart screenState={screenState} />
          </Content>
        )}
        rightStyle={{ flex: 3 }}
      />


    </Layout>
  }
}

export default connect(
  (state) => ({
    catalog: state.catalog,
    activeCartId: state.cart.activeCartId
  }),
  null)(PDV);
