import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Button, Icon, Modal, Input, Layout, Form, Menu, Radio, Card, notification } from 'antd';
import Colors from '../constants/Colors'
import Helpers from '../utils/Helpers'
import { setCart, getCart, addPaymentToCart, removePaymentFromCart, removeCart, newCart } from '../store/cartsReducer'
import { addSale, getSale } from '../store/salesReducer'
import Api from '../api/Api'
import DiscountInput from './DiscountInput'
import PaymentRow from './PaymentRow'
import CreditCardInput from './CreditCardInput'
import CreditCardNSUInput from './CreditCardNSUInput'
import MoneyInput from './MoneyInput'
import SaleDetails from './SaleDetails'
import { PaymentMethod, PaymentMethodBR, PaymentType } from '../constants/Enums'
import WebPOSHelper, { loadWebPOSLoader } from '../utils/webpos.helper'
var SimpleCurrencyInput = require('react-simple-currency');
var Big = require('big.js');

class PaymentModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modalOpened: false,
            method: PaymentMethod.CREDIT,
            type: PaymentType.TEF,
            value: props.activeCart && props.activeCart.amountDue || 0,
            valueRaw: props.activeCart && Math.ceil(Number(new Big(props.activeCart.amountDue).times(100))) || 0,
            // validation:
            paymentValueOk: true,
            paymentValueError: '',
            // saleId
            openedSaleId: null,
            // tef
            parcelas: 1
        }
        if (this.state.value < 0) {
            this.state.value = 0;
            this.state.valueRaw = 0;
        }
        if (props.myRef) props.myRef(this);

        // webpos
        loadWebPOSLoader()
    }

    // TEF STUFF
    payTefCredit = async () => {
        const webpos = await loadWebPOSLoader();
        webpos.pay.credit(this.state.value, this.state.parcelas,
            tefData => {
                console.log('tefData', JSON.stringify(tefData));
                this.setState({ tefData });
                this.registerPayment(this.state.value, tefData);
                // print tef data
                // no auto print no more
                //var tx = "Pagamento em cartão\n " + tefData.CupomReduzido.replaceAll('"', '').replaceAll('\r', '') + '\n------------------------------------------';
                //webpos.print(tx, true, false, (ok) => { }, (err) => { });
            },
            () => {
                console.error('Falha no pagamento!')
            }
        )
    }

    payTefDebit = async () => {
        const webpos = await loadWebPOSLoader();
        webpos.pay.debit(this.state.value,
            tefData => {
                console.log('tefData', JSON.stringify(tefData));
                this.setState({ tefData });
                this.registerPayment(this.state.value, tefData);
                // print tef data
                // no autoprint no more
                //var tx = "Pagamento em cartão\n " + tefData.CupomReduzido.replaceAll('"', '').replaceAll('\r', '') + '\n------------------------------------------';
                //webpos.print(tx, true, false, (ok) => { }, (err) => { });
            },
            () => {
                console.error('Falha no pagamento!')
            }
        )
    }

    componentWillReceiveProps(nextProps) {
        var newState = { valueRaw: Number(Math.ceil(new Big(nextProps.activeCart.amountDue).times(100))), value: nextProps.activeCart.amountDue };
        if (newState.value < 0) {
            newState.value = 0;
            newState.valueRaw = 0;
        }
        this.setState(newState);
    }
    onSetPaymentOk = () => {
        this.setState({ modalOpened: true });
    }
    addPayment = () => {
        this.setState({ modalOpened: true })
    }
    registerPayment = (val, data) => {
        var payment = {
            method: this.state.method,
            value: val || this.state.value,
            data: data || {}
        };
        if (payment.method == PaymentMethod.CREDIT || payment.method == PaymentMethod.DEBIT) {
            payment.type = this.state.type;
            if (payment.type == PaymentType.TEF) {
                //payment.data = {}
            }
            else if (payment.type == PaymentType.POS) {
                //payment.data = { ...(payment.data)
                //    NSU: this.state.nsu
                //}
            } else if (payment.type == PaymentType.ONLINE) {
                payment.data = {
                    OnlineData: {}
                }
            }
        }
        this.props.dispatch(addPaymentToCart(payment, this.props.activeCart.guid));
    }
    removePayment = async (payment) => {
        if (payment.type == PaymentType.TEF) {
            console.log('payment:', payment);
            const webpos = await loadWebPOSLoader();
            webpos.transactions.cancel(payment.data.NumeroControle,
                (aprov,transac) => {
                    console.log('retCancelamento', JSON.stringify(aprov), JSON.stringify(transac));
                    notification['success']({
                        message: 'Transação cancelada com sucesso!',
                        description: 'O valor desta transação foi estornado no cartão! =)',
                        duration: 8
                    });
                    var imprime = window.confirm('Deseja imprimir o comprovante do cancelamento?');
                    if(imprime){
                        var tx = aprov.CupomCliente.replaceAll('"', '').replaceAll('\r', '') + '\n------------------------------------------';
                        webpos.print(tx, true, true, (ok) => { }, (err) => { });
                    }
                    this.props.dispatch(removePaymentFromCart(payment.id, this.props.activeCart.guid));
                },
                () => {
                    //console.error('Falha no pagamento!')
                    notification['error']({
                        message: 'Transação não cancelada!',
                        description: 'Ocorreu um erro ao tentar cancelar esta transação! =(',
                        duration: 8
                    });
                }
            )
        } else {
            this.props.dispatch(removePaymentFromCart(payment.id, this.props.activeCart.guid));
        }
    }
    onPaymentCancel = () => {
        this.setState({ modalOpened: false })
    }
    onCloseSaleDetailsModal = () => {
        //Api.Sales.syncSale(this.thatSale);
    }
    onPaymentRemove = () => {
        var newCart = { ...(this.props.activeCart), discount: null }
        this.props.dispatch(setCart(this.props.activeCart.guid, newCart));
        this.setState({ modalOpened: false })
    }
    renderMoneyOptions = () => {
        var val = this.props.activeCart.amountDue;
        if (val <= 0) return null;
        var opts = [val];
        var v = 0;
        // 0.25c multiples
        v = Math.ceil(val / 0.25) * .25;
        if (opts.indexOf(v) == -1) opts.push(v);
        // $1 multiples
        v = Math.ceil(val);
        if (opts.indexOf(v) == -1) opts.push(v);
        // $2 multiples
        v = Math.ceil(val / 2) * 2;
        if (opts.indexOf(v) == -1) opts.push(v);
        // $5 multiples
        v = Math.ceil(val / 5) * 5;
        if (opts.indexOf(v) == -1) opts.push(v);
        // $10 multiples
        v = Math.ceil(val / 10) * 10;
        if (opts.indexOf(v) == -1) opts.push(v);
        // $20 multiples
        v = Math.ceil(val / 20) * 20;
        if (opts.indexOf(v) == -1 && opts.length < 6) opts.push(v);
        // $50 multiples
        v = Math.ceil(val / 50) * 50;
        if (opts.indexOf(v) == -1 && opts.length < 6) opts.push(v);

        return opts.map(x => (<Button type="primary" size="large" style={{ margin: 8 }} key={x}
            onClick={() => this.registerPayment(x)}>
            {
                Helpers.FloatToReais(x).replace(',00', '')
            }
        </Button>)
        );
    }

    registerSale = () => {
        var saleId = this.props.activeCart.guid;
        var saleToAdd = this.props.activeCart;
        saleToAdd.StoreId = Api.StoreId;
        var isSiteSale = Helpers.Any(Helpers.ObjToArray(this.props.activeCart.products), 'SiteSale', true);
        saleToAdd.origin = isSiteSale ? Api.SaleOrigins.iFood : Api.SaleOrigins.POS;
        saleToAdd.User = this.props.loggedUser;
        saleToAdd.cashierId = this.props.loggedUser.id;

        this.props.dispatch(addSale(saleToAdd));
        //Api.Sales.syncSale(saleToAdd);
        this.thatSale = saleToAdd;
        this.props.dispatch(removeCart(saleId));
        this.props.dispatch(newCart());
        this.setState({ openedSaleId: saleId, modalOpened: false });
    }

    printPayment = async (pmt, fullCut = true) => {
        const webpos = await loadWebPOSLoader();
        var tx = "Pagamento em cartão\n " + pmt.data.CupomCliente.replaceAll('"', '').replaceAll('\r', '') + '\n------------------------------------------';
        webpos.print(tx, true, fullCut, (ok) => { }, (err) => { });
    }

    render() {
        var prod = this.state.prod;
        console.log('Amount due: ', this.props.activeCart.amountDue)
        return <div>
            <Modal
                width={720}
                visible={this.state.modalOpened}
                title={`Pagamento (Total: ${Helpers.FloatToReais(this.props.activeCart.sum)})`}
                onOk={this.onSetPaymentOk}
                onCancel={this.onPaymentCancel}
                footer={<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Button key="cancel" size="large" type="default" onClick={this.onPaymentCancel} icon="close">Voltar</Button>
                    <span style={{ marginLeft: 10, fontSize: 18, lineHeight: '37px', color: Colors.themeColor }}>{
                        this.props.activeCart.amountDue == 0 ? '' :
                            this.props.activeCart.amountDue != 0 && (this.props.activeCart.amountDue < 0 ?
                                "Troco esperado de " + Helpers.FloatToReais(-this.props.activeCart.amountDue) :
                                "Ainda falta pagar " + Helpers.FloatToReais(this.props.activeCart.amountDue))
                    }
                    </span>
                    <Button key="submit" size="large" type="primary" onClick={() => this.registerSale()} icon="shopping-cart" disabled={this.props.activeCart.amountDue > 0}>Concluir</Button>
                </div>}
            >
                <div className="paymentPopupPrice">
                    <Form>
                        <Form.Item style={{ flex: 1, marginRight: 20 }}
                            label="Valor"
                            validateStatus={!this.state.paymentValueOk ? 'error' : ''}
                            help={this.state.paymentValueError || ''}
                        >
                            <SimpleCurrencyInput
                                className='ant-input ant-input-lg txCenter inputHuge'
                                value={this.state.valueRaw}
                                precision={2}
                                separator=','
                                delimiter='.'
                                unit='R$'
                                onInputChange={(raw, val) => { this.setState({ valueRaw: raw, value: Number(new Big(raw).div(100)) }); }}
                            />
                        </Form.Item>
                    </Form>
                </div>
                {this.props.activeCart.amountDue > 0 &&
                    <div className="paymentBox">
                        <Menu
                            onClick={(item) => {
                                this.setState({ method: item.key })
                                if (item.key == PaymentMethod.CREDIT) this.setState({ type: PaymentType.TEF })
                                if (item.key == PaymentMethod.DEBIT) this.setState({ type: PaymentType.TEF })
                            }}
                            selectedKeys={[this.state.method]}
                            mode="horizontal"
                        >
                            <Menu.Item key={PaymentMethod.CREDIT}>
                                <Icon type="credit-card" />Crédito
                    </Menu.Item>
                            <Menu.Item key={PaymentMethod.DEBIT}>
                                <Icon type="credit-card" />Débito
                    </Menu.Item>
                            <Menu.Item key={PaymentMethod.MONEY}>
                                <Icon type="wallet" />Dinheiro
                    </Menu.Item>
                            <Menu.Item key={PaymentMethod.INSTAGRANA}>
                                <Icon type="qrcode" />Instagrana
                    </Menu.Item>
                        </Menu>
                        <div style={{ marginTop: 0, textAlign: 'center' }}>

                            {this.state.method == PaymentMethod.CREDIT ?  //  ========== TAB CREDITO =========
                                <Card bodyStyle={{ padding: 0 }}>
                                    <Menu mode="horizontal" selectedKeys={[this.state.type]} onClick={(item) => this.setState({ type: item.key })}>
                                        <Menu.Item key={PaymentType.POS}>Máquina POS</Menu.Item>
                                        <Menu.Item key={PaymentType.TEF}>Leitor USB TEF</Menu.Item>
                                        <Menu.Item key={PaymentType.ONLINE}>Crédito Online</Menu.Item>
                                    </Menu>
                                    <div>
                                        {this.state.type == PaymentType.POS ?    /// ------> POS (comprovante)
                                            <div style={{ margin: 15 }}>
                                                <CreditCardNSUInput ref="nsuCredit" />
                                                <div style={{ marginBottom: 15 }}>
                                                    <Button type="primary" size="large" onClick={() => this.registerPayment(this.state.value, { nsu: this.refs.nsuCredit.getValue() })}
                                                        disabled={this.state.value <= 0}>
                                                        Registrar pagamento de {Helpers.FloatToReais(this.state.value)}</Button>
                                                </div>
                                            </div>

                                            :
                                            this.state.type == PaymentType.TEF ?     /// ------> TEF USB ( webpos )
                                                <div style={{ margin: 50 }}>
                                                    <Button type="primary" size="large" onClick={() => this.payTefCredit()}
                                                        disabled={this.state.value <= 0}>
                                                        Cobrar {Helpers.FloatToReais(this.state.value)} com Leitor USB</Button>
                                                </div>
                                                :
                                                /// ------> Online ( CyberCash )
                                                <div>
                                                    <CreditCardInput />
                                                    <div style={{ marginBottom: 15 }}>
                                                        <Button type="primary" size="large" onClick={() => this.registerPayment()}
                                                            disabled={this.state.value <= 0}>
                                                            Cobrar {Helpers.FloatToReais(this.state.value)} Online</Button>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                </Card>
                                :
                                this.state.method == PaymentMethod.DEBIT ? //   ========== TAB DÉBITO =========
                                    <Card bodyStyle={{ padding: 0 }}>
                                        <Menu mode="horizontal" selectedKeys={[this.state.type]} onClick={(item) => this.setState({ type: item.key })}>
                                            <Menu.Item key={PaymentType.POS}>Máquina POS</Menu.Item>
                                            <Menu.Item key={PaymentType.TEF}>Leitor USB TEF</Menu.Item>
                                        </Menu>
                                        <div>
                                            {this.state.type == PaymentType.POS ?    /// ------> POS (comprovante)
                                                <div style={{ margin: 15 }}>
                                                    <CreditCardNSUInput ref="nsuDebit" />
                                                    <div style={{ marginBottom: 15 }}>
                                                        <Button type="primary" size="large" onClick={() => this.registerPayment(this.state.value, { nsu: this.refs.nsuDebit.getValue() })}
                                                            disabled={this.state.value <= 0}>
                                                            Registrar pagamento de {Helpers.FloatToReais(this.state.value)}</Button>
                                                    </div>
                                                </div>
                                                :
                                                this.state.type == PaymentType.TEF ?     /// ------> TEF USB ( webpos )
                                                    <div style={{ margin: 50 }}>
                                                        <Button type="primary" size="large" onClick={() => this.payTefDebit()}
                                                            disabled={this.state.value <= 0}>
                                                            Cobrar {Helpers.FloatToReais(this.state.value)} com Leitor USB</Button>
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </Card>
                                    :
                                    this.state.method == PaymentMethod.MONEY ?
                                        <Card bodyStyle={{ paddingTop: 15, paddingBottom: 15 }}>
                                            <div>
                                                {/* <Form>
                                            <MoneyInput label="Valor pago em dinheiro" value={this.state.value} 
                                                onChange={(val)=>this.setState({value:val, valueRaw: val*100})} />
                                        </Form> */}
                                                {this.props.activeCart.amountDue > 0 ? <h4>Quanto foi pago em dinheiro?</h4> : null}
                                                <div style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                                    {this.renderMoneyOptions()}
                                                </div>
                                                <div style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 15 }}>
                                                    <h4 style={{ display: 'inline', marginRight: 15 }}>Outro valor:</h4>
                                                    <SimpleCurrencyInput
                                                        className='ant-input ant-input-lg txCenter w150'
                                                        value={this.state.valueRaw}
                                                        precision={2}
                                                        separator=','
                                                        delimiter='.'
                                                        unit='R$'
                                                        onInputChange={(raw, val) => { this.setState({ valueRaw: raw, value: raw / 100 }); }}
                                                    />
                                                    <Button type="primary" size="large" onClick={() => this.registerPayment()}
                                                        disabled={this.state.value <= 0} icon="check">
                                                    </Button>
                                                    <h4 style={{ marginTop: 12, marginLeft: 15, display: 'inline' }}>
                                                        {this.state.value > this.props.activeCart.sum ?
                                                            `Troco: ${Helpers.FloatToReais(this.state.value - this.props.activeCart.sum)}`
                                                            :
                                                            ''
                                                        }
                                                    </h4>
                                                </div>
                                            </div>
                                        </Card>
                                        : null
                            }
                        </div>
                    </div>
                }
                {this.props.payments.length > 0 ?
                    <div className="codebox">
                        <span className="codebox-title">Pagamentos:</span>
                        {this.props.payments.map((p, i) => (<PaymentRow key={i} payment={p}
                            onRemovePayment={() => this.removePayment(p)}
                            onClick={p.type == PaymentType.TEF && p.data.CupomReduzido ? () => this.printPayment(p, true) : null}
                            noBorder={(i + 1) == this.props.payments.length} editable={true} />))}
                    </div> : null}
            </Modal>

            <Modal
                width={720}
                visible={this.state.openedSaleId != null}
                title={`Venda`}
                maskClosable={false}
                onOk={() => { this.setState({ openedSaleId: null }); }}
                onCancel={() => { this.setState({ openedSaleId: null }); }}
                footer={<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Button key="submit" size="large" type="primary"
                        onClick={() => {
                            this.setState({ openedSaleId: null }
                                //,()=>this.onCloseSaleDetailsModal()
                            )
                        }
                        } icon="check">Ok</Button>
                </div>}
            >
                <div>
                    <SaleDetails screenState={this.props.screenState} saleId={this.state.openedSaleId} onCloseModal={this.onCloseSaleDetailsModal} />
                </div>
            </Modal>
        </div>
    }
}
export default connect(
    (state) => ({
        activeCart: getCart(state.cart.carts, state.cart.activeCartId),
        payments: getCart(state.cart.carts, state.cart.activeCartId).payments,
        activeCartTotal: getCart(state.cart.carts, state.cart.activeCartId) && getCart(state.cart.carts, state.cart.activeCartId).sum || 0,
        loggedUser: state.auth.userAuth
    }),
    null)(PaymentModal);
