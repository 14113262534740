import React, { Component } from 'react';
import { connect } from 'react-redux'
import Product from '../components/Product'
import Api from '../api/Api'
import Cart from '../components/Cart'
import Colors from '../constants/Colors'
import Helpers from '../utils/Helpers'
import GlobalStyles from '../constants/GlobalStyles'
import { addToCart, setActiveCart } from '../store/cartsReducer'
import { Layout, Menu, Icon, Tabs, Button, Input } from 'antd';
import ReactTable from 'react-table'
import 'react-table/react-table.css'
const TabPane = Tabs.TabPane;
const { Header, Content, Footer, Sider } = Layout;
var moment = require('moment');
class Products extends Component {
  constructor(props) {
    super(props)
    var categories = null;
    if (props.catalog && props.catalog.products)
      categories = props.catalog.products;
    this.state = {
      categories: categories,
      searchText: '',
      page: localStorage.productsPageNumber || 0
    }
  }

  componentDidMount() {
    console.log('fetch products from Products.js')
    //Api.Products.getProducts();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.catalog && nextProps.catalog.products)
      this.setState({ categories: nextProps.catalog.products });
  }

  columns = [
    { Header: 'Id', accessor: 'Id', maxWidth: 52 },
    
    //{ Header: 'Data', accessor: 'DataCriado' },
    {
      Header: 'Name', accessor: 'Name',
      filterMethod: (filter, row) => Helpers.ToLowerSemAcentos(row[filter.id]).indexOf(Helpers.ToLowerSemAcentos(filter.value))>-1
    },
    { Header: 'Estoque', accessor: 'QtyInStock', maxWidth: 68 },
    {
      Header: 'Hashtags', id: 'Tags', accessor: d => d.Tags ? d.Tags.join(', ') : '',
      filterMethod: (filter, row) => Helpers.ToLowerSemAcentos(row[filter.id]).indexOf(Helpers.ToLowerSemAcentos(filter.value))>-1
    },
    { Header: 'Preço', id: 'Price', maxWidth: 64, accessor: d => d.Price > 0 ? d.Price.toFixed(2) : d.Price,
        sortMethod: (a, b) => {
          if (a === b) {
            return 0;
          }
          var fA = isNaN(a) ? 0 : Number(a);
          var fB = isNaN(b) ? 0 : Number(b);
          return b-a;
        }
     },
    {
      Header: 'Validade', id: 'ExpirationDate', maxWidth: 100,
      accessor: d => (d.ExpirationDate ? moment(d.ExpirationDate).format('DD/MM/YYYY') : ''),
      sortMethod: (a, b) => {
        if (a === b) {
          return 0;
        }
        const aArr = a.split("/");
        var aDate = new Date(aArr[2],aArr[1]-1,aArr[0]);
        const bArr = b.split("/");
        var bDate;
        if(bArr.length==3){
          bDate = new Date(bArr[2],bArr[1]-1,bArr[0]);
        } else {
          bDate = new Date(2000,0,1);
        }
        return bDate - aDate;
      }
    },
    //{ Header: 'Status', accessor: 'StatusTransacaoString' },
  ];

  render() {
    return <Layout style={{ minWidth: 800, flexDirection: 'column', backgroundColor: '#fff' }}>
      <ReactTable defaultPageSize={25} showPageSizeOptions={true}
        previousText={'Anterior'}
        nextText={'Próximo'}
        loadingText={'Carregando...'}
        noDataText={'Sem resultados'}
        pageText={'Página'}
        ofText={'de'}
        rowsText={'linhas'}
        data={this.state.categories}
        columns={this.columns}
        page={this.state.page * 1}
        filterable
        onPageChange={(i) => this.setState({ page: i }, () => localStorage.productsPageNumber = i)}
      />
    </Layout>
  }
}

export default connect(
  (state) => ({
    catalog: state.catalog,
  }),
  null)(Products);
