import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Button, Icon, Modal, Input, Layout, Form } from 'antd';
import Colors from '../constants/Colors'
import Helpers from '../utils/Helpers'
import { setCustomerToCart } from '../store/cartsReducer'
import Api from '../api/Api'
import DiscountInput from './DiscountInput'
import CurrencyInput from 'react-currency-input';
import CustomerEdit from './CustomerEdit';
var SimpleCurrencyInput = require('react-simple-currency');
var Big = require('big.js');

class ProductEdit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modalEditCustomer: false,
            customer: props.customer
        }
        if (props.myRef) props.myRef(this);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.customer){
            this.setState({customer:nextProps.customer});
        }
    }

    showModal = () => {
        this.setState({modalEditCustomer:true});
    }

    setCustomer = (customer) => {
        this.setState({customer});
    }

    validateBirth = () => {
        var bday = this.state.customer.birth;
        if(!bday) return bday; // ok, data nasc eh opcional
        if(bday.indexOf('/') == -1) { // não tem '/'? 
            return false;
        } 
        var splitedBday = bday.split('/');
        var day,month,year;
        if(splitedBday.length == 2){ // so mandou mes e ano
            day = splitedBday[0];
            month = splitedBday[1];
            year = 1900;
        }
        else if(splitedBday.length == 3){
            day = splitedBday[0];
            month = splitedBday[1];
            year = splitedBday[2];
            if(year < 1900) year = 1900;
        } else {
            return false;
        }
        var dtNascFinal;
        try { 
            dtNascFinal = new Date(year,month-1,day);
            return dtNascFinal;
        } catch(e) { return false; }
    }

    onEditCustomerOk = () => {
        var customer = this.state.customer;
        // verify birth:
        var dtNasc = this.validateBirth();
        if(!dtNasc) {
            alert('Data de nascimento inválida!');
            return;
        }
        console.log('dispatch setCustomer: ', customer);
        this.props.dispatch(setCustomerToCart(customer, this.props.cartId));
        this.setState({modalEditCustomer:false});
    }

    onEditCustomerRemove = () => {
        this.props.dispatch(setCustomerToCart(null, this.props.cartId));
        this.setState({modalEditCustomer:false, customer:{}});
    }

    onEditCustomerCancel = () => {
        this.setState({modalEditCustomer:false});
    }

    render() {
        var customer = this.state.customer;
        return <Modal
            visible={this.state.modalEditCustomer}
            title={'Associar cliente ao carrinho'
                //`${prod && prod.Name || 'Produto avulso'}`
            }
            onOk={this.onEditCustomerOk}
            onCancel={this.onEditCustomerCancel}
            footer={<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Button key="remove" size="large" type="danger" onClick={this.onEditCustomerRemove} icon="delete">Remover</Button>
                <Button key="submit" size="large" type="primary" onClick={this.onEditCustomerOk} icon="user-add">
                    {'Associar'}
                </Button>
            </div>}
        >
            <CustomerEdit customer={customer} onChangeCustomer={(customer) => this.setCustomer(customer)} />
        </Modal>
    }
}

const styles = {
    
}
export default connect(
    (state) => ({
        activeCartId: state.cart.activeCartId,
    }),
    null)(ProductEdit);