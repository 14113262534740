import Guid from 'guid'
import Helpers from '../utils/Helpers'
var Big = require('big.js');

const ADD_TO_CART = 'ADD_TO_CART'
const SET_ACTIVE_CART_ID = 'SET_ACTIVE_CART_ID'
const SET_CART = 'SET_CART'
const REMOVE_CART = 'REMOVE_CART'
const CLEAR_ALL_CARTS = 'CLEAR_ALL_CARTS'
const NEW_CART = 'NEW_CART'
const SET_PRODUCT_TO_ACTIVE_CART = 'SET_PRODUCT_TO_ACTIVE_CART'
const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
const ADD_PAYMENT_TO_CART = 'ADD_PAYMENT_TO_CART'
const REMOVE_PAYMENT_FROM_CART = 'REMOVE_PAYMENT_FROM_CART'
const SET_CUSTOMER_TO_CART = 'SET_CUSTOMER_TO_CART'

export const setActiveCartId = (cartId) => ({
  type: SET_ACTIVE_CART_ID,
  payload: cartId
})
export const setCart = (cartId, cart) => ({
  type: SET_CART,
  payload: { cartId, cart }
})

export const addToCart = (prod, cartId) => ({
  type: ADD_TO_CART,
  payload: { prod, cartId }
})
export const removeFromCart = (prod, cartId) => ({
  type: REMOVE_FROM_CART,
  payload: { prod, cartId }
})

export const addPaymentToCart = (payment, cartId) => ({
  type: ADD_PAYMENT_TO_CART,
  payload: { payment, cartId }
})
export const removePaymentFromCart = (paymentId, cartId) => ({
  type: REMOVE_PAYMENT_FROM_CART,
  payload: { paymentId, cartId }
})
export const setCustomerToCart = (customer, cartId) => ({
  type: SET_CUSTOMER_TO_CART,
  payload: { customer, cartId }
})

export const clearAllCarts = () => ({
  type: CLEAR_ALL_CARTS,
  payload: {}
})
export const newCart = () => ({
  type: NEW_CART,
  payload: {}
})
export const removeCart = (cartId) => ({
  type: REMOVE_CART,
  payload: { cartId }
})
export const setProductToActiveCart = (prod) => ({
  type: SET_PRODUCT_TO_ACTIVE_CART,
  payload: { prod }
})

export const getCart = (carts = {}, cartId) => {
  return packCart(carts[cartId] || {
    guid: cartId,
    name: 'Carrinho',
    createdAt: new Date(),
    products: {},
    payments: []
  });
}

const addToCartReducer = (state, prod, cartId) => {
  console.log('adding prod to cart', prod.Id, cartId);
  // get (new?) cart
  var cart = getCart(state.carts, cartId)
  // set product on cart
  var prodFound = cart.products[prod.Id];
  if (prodFound) { // already had this prod on cart (let add Quantity)
    cart.products[prod.Id] = { ...prodFound, Quantity: Number(new Big(prodFound.Quantity).plus(prodFound.QtyToDisplayPrice || 1)) };
  } else {
    cart.products[prod.Id] = { ...prod, Quantity: (prod.QtyToDisplayPrice || 1) };
  }
  // pack cart and return
  var newCartsState = { ...state };
  newCartsState.carts[cartId] = packCart(cart);
  return newCartsState;
}

// packing cart (sum, totals, etc)
const packCart = (cart) => {
  //console.log('packing cart:',JSON.stringify(cart))
  cart.sum = Helpers.ObjToArray(cart.products).reduce((a, b) => {
    var totalProdVal = Number(new Big(b.Price).times(b.Quantity));
    // products individual discounts
    if (b.discount) {
      if (b.discount.type == 'percent')
        totalProdVal = Number(new Big(totalProdVal).times(new Big(1).minus(new Big(b.discount.value).div(100))));
      else if (b.discount.type == 'value')
        totalProdVal = Number(new Big(totalProdVal).minus(b.discount.value));
    }
    return Number(new Big(a).plus(totalProdVal.toFixed(2)));
  }, 0);
  // cart global discount?
  if (cart.discount) {
    if (cart.discount.type == 'percent')
      cart.sum = Number(new Big(cart.sum).times(new Big(1).minus(new Big(cart.discount.value).div(100))));
    else if (cart.discount.type == 'value')
      cart.sum = Number(new Big(cart.sum).minus(cart.discount.value));
  }
  // totals:
  cart.qtd = Helpers.ObjToArray(cart.products).reduce((a, b) => Number(new Big(a).plus(b.Quantity)), 0);
  cart.amountPaid = cart.payments.reduce((a, b) => Number(new Big(a).plus(b.value)), 0) || 0;
  cart.amountDue = Number(new Big(cart.sum).minus(cart.amountPaid));
  //console.log('packed Cart:', JSON.stringify(cart));
  return cart;
}

const clearEmptyCarts = (carts) => {
  var newCarts = {};
  Object.keys(carts).map(x => {
    var c = carts[x];
    // has products or payments or customer or discount?
    if (Object.keys(c.products).length > 0 || c.payments.length > 0 || c.customer || c.discount)
      newCarts[x] = c;
  })
  return newCarts;
}

const initialState = {
  activeCartId: Guid.raw(),
  carts: {}
}

export function cartsReducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case ADD_TO_CART:
      return addToCartReducer(state, payload.prod, payload.cartId)
    case REMOVE_FROM_CART:
      var newState = { ...state }
      var cart = getCart(newState.carts, payload.cartId);
      delete cart.products[payload.prod.Id];
      newState.carts[payload.cartId] = packCart(cart);
      return newState;
    case SET_ACTIVE_CART_ID:
      var newState = { ...state };
      newState.carts = clearEmptyCarts(newState.carts);
      return { ...newState, activeCartId: payload }
    case SET_CART:
      var newState = { ...state };
      newState.carts = clearEmptyCarts(newState.carts);
      newState.carts[payload.cartId] = packCart(payload.cart);
      return newState;
    case REMOVE_CART:
      var newState = { ...state };
      newState.carts = clearEmptyCarts(newState.carts);
      delete newState.carts[payload.cartId];
      return newState;
    case CLEAR_ALL_CARTS:
      return { ...initialState }
    case NEW_CART:
      var newState = { ...state }
      var activeCartId = Guid.raw();
      newState.carts[activeCartId] = getCart(newState.carts, activeCartId);
      newState.carts = clearEmptyCarts(newState.carts);
      newState.activeCartId = activeCartId;
      return newState;
    case SET_PRODUCT_TO_ACTIVE_CART:
      var newState = { ...state }
      var cart = getCart(newState.carts, newState.activeCartId);
      cart.products[payload.prod.Id] = payload.prod;
      newState.carts[newState.activeCartId] = packCart(cart);
      return newState;
    // PAYMENTS:
    case SET_CUSTOMER_TO_CART:
      var newState = { ...state }
      var cart = getCart(newState.carts, payload.cartId);
      cart.customer = payload.customer;
      newState.carts[payload.cartId] = { ...packCart(cart) };
      return newState;
    // PAYMENTS:
    case ADD_PAYMENT_TO_CART:
      var newState = { ...state }
      var cart = getCart(newState.carts, payload.cartId);
      payload.payment.id = Guid.raw();
      cart.payments.push(payload.payment);
      newState.carts[payload.cartId] = { ...packCart(cart) };
      return newState;
    // PAYMENTS:
    case REMOVE_PAYMENT_FROM_CART:
      var newState = { ...state }
      var cart = getCart(newState.carts, payload.cartId);
      cart.payments = cart.payments.filter(p => p.id != payload.paymentId);
      newState.carts[payload.cartId] = { ...packCart(cart) };
      return newState;


    default:
      return state
  }
}