import { PaymentMethod, PaymentMethodBR, PaymentType } from '../constants/Enums'
import equal from 'deep-equal'
//import Decimal from './decimal'

const appendScript = (pathToScript) => new Promise(resolve => {
    var head = document.getElementsByTagName("head")[0];
    var js = document.createElement("script");
    js.type = "text/javascript";
    js.src = pathToScript;
    js.async = false;
    js.onload = resolve;
    head.appendChild(js);
})

let WebPOSLoader = window.WebPOSLoader;
let WebPOS = window.WebPOS;

const loadScripts = () => {
    appendScript(WebPOSLoader.baseUrl + 'Scripts/jquery-1.6.4.min.js')
        .then(() => appendScript(WebPOSLoader.baseUrl + 'Scripts/jquery.signalR-2.2.0.min.js'))
        .then(() => appendScript(WebPOSLoader.baseUrl + 'signalr/hubs'))
        .then(() => appendScript(WebPOSLoader.baseUrl + 'Scripts/toastr.min.js'))
        .then(() => appendScript(WebPOSLoader.baseUrl + 'Scripts/webpos.js?_=6')) //+(new Date().getTime())))
        .then(() => WebPOSLoader.appendStylesheet(WebPOSLoader.baseUrl + 'Scripts/toastr.min.css'))
}

const WebPOSWrapper = {}
export default WebPOSWrapper

export const loadWebPOSLoader = () => new Promise((resolve, reject) => {
    if (!window.WebPOSLoader || equal(WebPOSWrapper, {})) {
        const p = appendScript("https://www.cybercash.com.br/scripts/webpos/webpos.loader.js")
            .then(() => window.WebPOSLoader.loadScripts = loadScripts)
            .then(() => new Promise(res => {
                WebPOSLoader = window.WebPOSLoader;
                WebPOSLoader.init(() => {
                    res(WebPOSWrapper.WebPOS = window.WebPOS)
                })
            }))

        resolve(p)
    } else {
        resolve(WebPOSWrapper.WebPOS)
    }
})

const enderecoVazio = end => !end || (!end.CEP && !end.Estado && !end.Cidade && !end.Bairro && !end.Rua)

// bcVisa = 0,
// bcMasterCard = 1,
// bcAmericanExpress = 2,
// bcSorocred = 3,
// bcOutros = 4
const getTBand = bandeira => {
    const b = bandeira.toLowerCase()

    return b.includes('visa') || b.includes('electron') ? 0 :
        b.includes('mastercard') ? 1 :
            b.includes('american') || b.includes('amex') ? 2 : 4
}

// fpDinheiro = 0,
// fpCheque = 1,
// fpCartaoCredito = 2,
// fpCartaoDebito = 3,
// fpCreditoLoja = 4,
// fpValeAlimentacao = 5,
// fpValeRefeicao = 6,
// fpValePresente = 7,
// fpValeCombustivel = 8,
// fpOutro = 9
const getTPag = x => x.method === PaymentMethod.MONEY ? 0 :
    x.method === 'Cheque' ? 1 :
        x.method === PaymentMethod.CREDIT ? 2 :
            x.method === PaymentMethod.DEBIT ? 3 :
                9

const getCnpjCredenciadora = x => {
    const lower = x.toLowerCase()
    if (lower === 'cielo')
        return '01027058000191'
    if (lower.includes('rede'))
        return '01425787000101'
    if (lower.includes('visa') || lower.includes('master') || lower.includes('hipercard'))
        return getCnpjCredenciadora('rede')
    if (lower.includes('amex') || lower.includes('diners') || lower.includes('discover') || lower.includes('elo'))
        return getCnpjCredenciadora('cielo')
    return null
}

export const transformNotaData = sale => {
    console.log('from', sale)
    const Destinatario = !sale.customer || !sale.customer.document ? null
        : {
            Documento: sale.customer.document,
            // xNome: sale.customer.name || '',
            // ContribuinteICMS = 1,
            // Isento = 2,
            // NaoContribuinte = 9
            indIEDest: 9,
            //email: sale.customer.mail || ''
        };
    if (Destinatario) {
        if (sale.customer.name)
            Destinatario.xNome = sale.customer.name;
        if (sale.customer.mail)
            Destinatario.email = sale.customer.mail;
        if (!enderecoVazio(sale.address)) {
            Destinatario.enderDest = {
                xLgr: sale.address.Rua,
                nro: sale.address.Numero,
                xCpl: sale.address.Complemento,
                xBairro: sale.address.Bairro,
                cMun: sale.address.CodIBGEMunicipio,
                xMun: sale.address.Cidade,
                UF: sale.address.Estado,
                CEP: sale.address.CEP,
                cPais: 1058,
                xPais: "BRASIL"
            }
            if (sale.customer.phone)
                Destinatario.enderDest.fone = sale.customer.phone;
        }
    }

    const Identificacao = {
        // ipVista = 0,
        // ipPrazo = 1,
        // ipOutras = 2
        // indPag: sale.payments.some(x => x.Parcelas !== 1) ? 1 : 0,
        // pcNao = 0,
        // pcPresencial = 1,
        // pcInternet = 2,
        // pcTeleatendimento = 3,
        // pcEntregaDomicilio = 4,
        // pcOutros = 9
        indPres: 1, //sale.retiradoNaLoja ? 1 : 4, //NFCe: deve ser 1 ou 4
        natOp: "VENDA",
        tpNF: 1, //TipoNFe.tnSaida,
        tpEmis: 1, //TipoEmissao.teNormal,
        tpImp: 1,//TipoImpressao.tiRetrato,
        finNFe: 1,//FinalidadeNFe.fnNormal,
        verProc: "3.000",
        //dhSaiEnt: new Date(),
        idDest: 1,//DestinoOperacao.doInterna,
        procEmi: 0,//ProcessoEmissao.peAplicativoContribuinte,
    }
    const Transporte = {
        modFrete: 3,//ModalidadeFrete.mfSemFrete //NFCe: Não pode ter frete
    }

    const Itens = sale.products.map((x, i) => {
        // HARDCODE
        x.CFOP = 5102;

        //TODO
        const imposto = {
            vTotTrib: 0.04,
            ICMS: //new ICMS
                {
                    ICMSSN102: //new ICMSSN102
                        {
                            // Csosn101 = 101,
                            // Csosn102 = 102,
                            // Csosn103 = 103,
                            // Csosn201 = 201,
                            // Csosn202 = 202,
                            // Csosn203 = 203,
                            // Csosn300 = 300,
                            // Csosn400 = 400,
                            // Csosn500 = 500,
                            // Csosn900 = 900
                            CSOSN: 102,
                            // OmNacional = 0,
                            // OmEstrangeiraImportacaoDireta = 1,
                            // OmEstrangeiraAdquiridaBrasil = 2,
                            // OmNacionalConteudoImportacaoSuperior40 = 3,
                            // OmNacionalProcessosBasicos = 4,
                            // OmNacionalConteudoImportacaoInferiorIgual40 = 5,
                            // OmEstrangeiraImportacaoDiretaSemSimilar = 6,
                            // OmEstrangeiraAdquiridaBrasilSemSimilar = 7,
                            // OmNacionalConteudoImportacaoSuperior70 = 8
                            orig: 0
                        }
                },
            //Se você tem os dados de toda a tributação persistida no banco em uma única tabela, utilize a classe NFe.Utils.Tributacao.Estadual.ICMSGeral para obter os dados básicos. Veja o método ObterIcmsBasico

            //ICMSUFDest = new ICMSUFDest()
            //{
            //    pFCPUFDest = 0,
            //    pICMSInter = 12,
            //    pICMSInterPart = 0,
            //    pICMSUFDest = 0,
            //    vBCUFDest = 0,
            //    vFCPUFDest = 0,
            //    vICMSUFDest = 0,
            //    vICMSUFRemet = 0
            //},
            COFINS: //new COFINS
                {
                    COFINSOutr: //new COFINSOutr
                        {
                            // cofins01 = 1,
                            // cofins02 = 2,
                            // cofins03 = 3,
                            // cofins04 = 4,
                            // cofins05 = 5,
                            // cofins06 = 6,
                            // cofins07 = 7,
                            // cofins08 = 8,
                            // cofins09 = 9,
                            // cofins49 = 49,
                            // cofins50 = 50,
                            // cofins51 = 51,
                            // cofins52 = 52,
                            // cofins53 = 53,
                            // cofins54 = 54,
                            // cofins55 = 55,
                            // cofins56 = 56,
                            // cofins60 = 60,
                            // cofins61 = 61,
                            // cofins62 = 62,
                            // cofins63 = 63,
                            // cofins64 = 64,
                            // cofins65 = 65,
                            // cofins66 = 66,
                            // cofins67 = 67,
                            // cofins70 = 70,
                            // cofins71 = 71,
                            // cofins72 = 72,
                            // cofins73 = 73,
                            // cofins74 = 74,
                            // cofins75 = 75,
                            // cofins98 = 98,
                            // cofins99 = 99
                            CST: 99,
                            pCOFINS: 0,
                            vBC: 0,
                            vCOFINS: 0
                        }
                },
            PIS: //new PIS
                {
                    PISOutr: //new PISOutr
                        {
                            // pis01 = 1,
                            // pis02 = 2,
                            // pis03 = 3,
                            // pis04 = 4,
                            // pis05 = 5,
                            // pis06 = 6,
                            // pis07 = 7,
                            // pis08 = 8,
                            // pis09 = 9,
                            // pis49 = 49,
                            // pis50 = 50,
                            // pis51 = 51,
                            // pis52 = 52,
                            // pis53 = 53,
                            // pis54 = 54,
                            // pis55 = 55,
                            // pis56 = 56,
                            // pis60 = 60,
                            // pis61 = 61,
                            // pis62 = 62,
                            // pis63 = 63,
                            // pis64 = 64,
                            // pis65 = 65,
                            // pis66 = 66,
                            // pis67 = 67,
                            // pis70 = 70,
                            // pis71 = 71,
                            // pis72 = 72,
                            // pis73 = 73,
                            // pis74 = 74,
                            // pis75 = 75,
                            // pis98 = 98,
                            // pis99 = 99
                            CST: 99,
                            pPIS: 0,
                            vBC: 0,
                            vPIS: 0
                        }
                }
        }

        var vDesc = x.discount == null ? 0 : x.discount.type == "percent" ? ((x.discount.value/100)*(x.Price*x.Quantity)) : x.discount.value;
        const prod = {
            cProd: i.toString().padStart(5, '0'),
            cEAN: x.EAN || null, //'0000000000000'
            xProd: x.Name,
            NCM: x.NCM || '39231090', // Outros
            CFOP: x.CFOP,
            uCom: "UNID",
            qCom: x.Quantity,
            vUnCom: x.Price,
            //vProd: new Decimal(x.Price).mul(x.Quantity).toNumber(),
            vProd: parseFloat((x.Price * x.Quantity).toFixed(2)),
            vDesc: vDesc,
            cEANTrib: x.EAN || null, //'0000000000000'
            uTrib: x.QtyName || "UNID",
            qTrib: x.Quantity,
            vUnTrib: x.Price,
            // ValorDoItemNaoCompoeTotalNF = 0,
            // ValorDoItemCompoeTotalNF = 1
            indTot: 1,
            //NVE: {"AA0001", "AB0002", "AC0002"},
            //CEST: ?

            //ProdutoEspecifico: new arma
            //{
            //    tpArma: TipoArma.UsoPermitido,
            //    nSerie: "123456",
            //    nCano: "123456",
            //    descr: "TESTE DE ARMA"
            //}
        }


        return {
            nItem: i + 1,
            prod: prod,
            imposto
        }
    })

    const Pagamentos = sale.payments.map(x => {
        const card = (x.method === PaymentMethod.CREDIT || x.method === PaymentMethod.DEBIT) && x.type === PaymentType.POS
            ? {
                // TipIntegradoAutomacao = 1,
                // TipNaoIntegrado = 2
                tpIntegra: 2,
                tBand: getTBand(x.ModalidadeBandeira || ''),
                cAut: x.CV,
                CNPJ: getCnpjCredenciadora(x.ModalidadeBandeira || '')
            } : (x.method === PaymentMethod.CREDIT || x.method === PaymentMethod.DEBIT) && x.type === PaymentType.TEF ? {
                tpIntegra: 1,
                tBand: getTBand(x.data.NomeBandeiraCartao || ''),
                cAut: x.data.CodigoAutorizacaoAdquirente,
                CNPJ: getCnpjCredenciadora(x.data.NomeAdquirente || '')
            } : null

        return {
            tPag: getTPag(x),
            vPag: x.value,
            card
        }
    });

    // troco?
    if (sale.sum < sale.amountPaid) {
        // bota troco no primeiro pagamento em dineiro
        var monis = Pagamentos.filter(x => x.tPag == 0); // 0=dinheiro
        if (monis.length > 0) {
            monis[0].troco = sale.amountPaid - sale.sum;
            monis[0].vPag = monis[0].vPag - monis[0].troco;
        }
    }

    const Fatura = {
        vLiq: sale.sum
    }

    const notaData = {
        Identificacao,
        Itens,
        Pagamentos,
        Fatura,
    }
    if (Destinatario) notaData.Destinatario = Destinatario;
    console.log('to', notaData)
    return notaData
}
