import { Popconfirm, message, Tabs, List, Skeleton, Layout } from 'antd';
import React, { Component } from 'react';
import Button from 'antd/lib/button';
import { connect } from 'react-redux'
import { setAllSales } from '../store/salesReducer';
import { removeUser } from '../store/usersReducer';
import ResponsiveScreen from '../components/ResponsiveScreen';

const TabPane = Tabs.TabPane;

class Settings extends Component {

  constructor(props) {
    super(props);
    this.state = {
      jsonSales: {}
    }
  }

  downloadSales = () => {
    var fileDownload = require('js-file-download');
    var data = JSON.stringify(this.props.sales);//localStorage["reduxPersist:sales"];
    fileDownload(data, 'sales.json');
  }

  handleSubmit = (e) => {
    this.props.dispatch(setAllSales(this.state.jsonSales));
  }

  handleFileChange = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      console.log('load ended:', reader.result);
      this.setState({
        jsonSales: JSON.parse(reader.result),
      });
    }

    reader.readAsText(file)
  }

  render() {
    return (<Layout style={{ flex: 1, alignSelf: 'stretch', flexDirection: 'column', justifyContent: 'center', alignItems: 'stretch' }}>
      <Tabs defaultActiveKey="1" style={{ flex: 1, padding: 15 }}>
        <TabPane tab="Sales" key="1" style={{ alignItems: 'center' }}>
          <div style={{ marginTop: 40, display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Button onClick={this.downloadSales} type="primary">Download Sales</Button>
            <div style={{ marginLeft: 25 }}>{Object.keys(this.props.sales).length} sales on local database</div>
          </div>
          <div style={{ marginTop: 40, display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <input className="fileInput"
              type="file"
              placeholder="input sales.json"
              onChange={(e) => this.handleFileChange(e)} />
            {this.state.jsonSales && <div style={{ marginLeft: 25, marginRight: 25 }}>JsonSales: {Object.keys(this.state.jsonSales).length} sales to set</div>}
            <Popconfirm title="Are you sure you want to OVERRIDE local sales?" onConfirm={() => this.handleSubmit()} onCancel={() => { }} okText="Yes" cancelText="No">
              <Button type="primary">Set sales</Button>
            </Popconfirm>
          </div>
        </TabPane>
        <TabPane tab="Users" key="2">
          <List
            itemLayout="horizontal"
            dataSource={this.props.users}
            renderItem={item => (
              <List.Item actions={[<a onClick={() => { if (window.confirm('Apagar usuario?')) { this.props.dispatch(removeUser(item.id)) } }}>remove</a>]}>
                <List.Item.Meta
                  title={<h5>{item.name} ({item.user})</h5>}
                  description={`Id: ${item.id} | role: ${item.role} | expires: ${item.expires}`}
                />
              </List.Item>
            )}
          />
        </TabPane>
      </Tabs>
    </Layout>);
  }
}

export default connect(
  (state) => ({
    sales: state.sales,
    users: state.users
  }),
  null)(Settings);
